import axios from 'axios'
import SolicitaExistente from './components/SolicitaPuestoExistente.vue'
import FormPerfilPuestos from '../EscalerasDeCrecimiento/componentsPerfilPuestos/FormPerfilPuestos.vue'
import SolicitaNuevo from './components/SolicitaPuestoNuevo.vue'
import SolicitudesPendientes from './components/SolicitudesPendientes.vue'
import SnackBarVue from '../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import BuscaPuestos from './components/BuscaPuestos.vue'
import DataTableGeneric from '../../../../../components/componentsExtra/DataTableGeneric.vue'
import LoadingBar from '../../../DesarrolloOrganizacional/components/loadingBar.vue'
import VerApegoExistente from './components/VerApegoExistente.vue'
// import VerOrganigramaPuestosPorSupervisor from "./components/VerOrganigramaPuestosPorSupervisor.vue";
import {
    deleteToken
} from '../../../../../store/Funciones/funciones'

export default {
    name: 'control-puestos',
    components: {
        SolicitaExistente,
        SolicitaNuevo,
        SolicitudesPendientes,
        SnackBarVue,
        BuscaPuestos,
        DataTableGeneric,
        LoadingBar,
        VerApegoExistente,
        FormPerfilPuestos,
        // VerOrganigramaPuestosPorSupervisor
    },
    props: [],
    data() {
        return {
            puestosDepto: [],
            search: '',
            textoSnack: "",
            colorSnack: "",
            tituloEmpEnPue: "",
            textoLoadingBar: "",
            puestoABuscar: "",
            loadContent: true,
            solicitaExistente: false,
            mustraSnack: false,
            muestraBuscador: false,
            verColaboradoresPorPuesto: false,
            verLoadingBar: false,
            muestraApegoExistente: false,
            solicitaNuevo: false,
            verSolicitudesPendientes: false,

            // itemsPerPage: 10,
            idPuesto: 0,
            datosPuesto: {},
            headerProps: {
                sortByText: "Ordenar por",
            },
            headers: [
                {
                    text: 'JOBCODE',
                    align: 'center',
                    value: 'jobcode',
                    class: 'primary white--text text-uppercase font-weight-bold rounded-tl-xl',
                },
                {
                    text: 'Puesto',
                    align: 'center',
                    value: 'descr',
                    class: 'primary white--text text-uppercase font-weight-bold',
                },
                {
                    text: 'Colaboradores asignados',
                    value: 'asignados',
                    class: 'primary white--text text-uppercase font-weight-bold',
                    align: 'center'
                },
                // {
                //     text: 'Apego a perfil',
                //     align: 'center',
                //     value: 'apego',
                //     class: 'primary white--text text-uppercase font-weight-bold',
                // },
                {
                    text: 'Acciones',
                    align: 'center',
                    value: 'actions',
                    class: 'primary white--text text-uppercase font-weight-bold rounded-tr-xl',
                }
            ],
            empEnPue: [],
            hedEmpEnPue: [{
                text: '# de Emp.',
                value: 'EMPLID'
            },
            {
                text: 'Nombre',
                value: 'NAME'
            },
            {
                text: 'Fecha de ingreso',
                value: 'HIRE_DT',
                align: 'center'
            },
            ],
            formPerfilPuestos: false,
            itemPuesto: [],
        }
    },
    computed: {

    },
    mounted() {
        setTimeout(() => {
            this.getPuestosDepto();
        }, 1000);
    },
    methods: {
        // getPuestosDepto() {
        //     //  window.document.title = "DH:URREA - Control de puestos.";
        //     let params = {
        //         Opcion: 1,
        //         token: localStorage.getItem('token')
        //     }
        //     axios
        //         .post(`${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`, params)
        //         .then((response) => {
        //             if (response.data.status != "EXPSESSION") {
        //                 this.puestosDepto = response.data.puestos
        //                 this.loadContent = false
        //             }

        //         })
        // },

        getPuestosDepto() {
            let correo = localStorage.getItem("correo");
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-puestos-por-area/${correo}`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            res(response.data.data);
                            this.puestosDepto = response.data.data;
                        } else {
                            res([]);
                        }
                    })
                    .finally(() => {
                        this.loadContent = false;
                    });
            });
        },

        solicitarPuestoExistente(jobcode) {
            this.idPuesto = jobcode;
            this.solicitaExistente = true;
        },
        cierraSolicitud(modoCierre) {
            if (this.solicitaExistente) {
                this.solicitaExistente = false;
            }
            if (this.solicitaNuevo) {
                this.solicitaNuevo = false;
            }
            if (this.muestraBuscador) {
                this.muestraBuscador = false;
            }

            if (modoCierre == 2) {
                this.colorSnack = "pink darken-1"
                this.textoSnack = "Ocurrió un error al intentar generar tu solicitud";
                this.mustraSnack = true;
            } else if (modoCierre == 1) {
                this.colorSnack = "success"
                this.textoSnack = "Solicitud generada con éxito. Espera una pronta respuesta de nuestro equipo de Reclutamiento";
                this.mustraSnack = true;
            }

        },
        // verColabPorPuesto(item) {
        //     this.textoLoadingBar = "Cargando colaboradores del puesto " + item.descr
        //     this.verLoadingBar = true;
        //     console.log(item)
        //     let params = {
        //         Opcion: 8,
        //         token: localStorage.getItem('token'),
        //         jobcode: item.jobcode
        //     }
        //     axios
        //         .post(`${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`, params)
        //         .then((response) => {
        //             if (response.data.status != "EXPSESSION") {
        //                 this.empEnPue = response.data.puestos
        //                 this.openGenericTable(item.descr)
        //             }

        //         })
        // },

        verColabPorPuestoNode(item) {
            this.textoLoadingBar = "Cargando colaboradores del puesto " + item.descr
            this.verLoadingBar = true;
            console.log(item)
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-colaboradores-por-puestos/${item.jobcode}`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status != "EXPSESSION") {
                            res(response.data.data)
                            this.empEnPue = response.data.data;
                            this.openGenericTable(item.descr);
                        }

                    })
                    .finally(() => {
                        this.loadContent = false;
                    });
            });
        },
        openGenericTable(titulo) {
            this.verLoadingBar = false
            this.tituloEmpEnPue = "Colaboradores asignados a " + titulo
            this.verColaboradoresPorPuesto = true
        },
        openVerApego(item) {
            this.puestoABuscar = item.jobcode
            this.muestraApegoExistente = true
        },

        solicitarPoliticas(idPuesto) {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };
            let params = {
                idPuesto: idPuesto,
                correo: localStorage.getItem("correo"),
            }

            return new Promise((res) => {
                axios
                    .post(
                        `${this.$store.getters.getHostNode}/api/set-solicitud-de-descripcion-de-puesto`,
                        params,
                        { headers }
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.colorSnack = 'teal darken-1'
                            this.textoSnack = "Solicitado con éxito. Espera la respuesta de compensaciones!"
                            this.mustraSnack = true;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else {
                            res([]);
                            this.colorSnack = 'pink darken-3'
                            this.textoSnack = "Ocurrió un error"
                            this.mustraSnack = true;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                        this.verLoadingBar = false;
                    });
            });
        },

        openForm(item) {
            this.formPerfilPuestos = true;
            this.itemPuesto = { id: item.jobcode_zn, descr: item.descr };
        },
        closeForm(response) {
            this.formPerfilPuestos = false;
            if (response == "OK") {
                // this.clean();
                // this.getPuestosPorNivel();
                this.puestosDepto = [];
                this.loadContent = true;
                this.getPuestosDepto();
                console.log(response);
            }
        },
    },

}