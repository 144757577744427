var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.proveedorHeaders,"items":_vm.proveedores,"expanded":_vm.expanded,"item-key":"No_proveedor","search":_vm.search,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        'page-text': '{0}-{1} de {2}',
    },"no-data-text":"No hay registros","no-results-text":'No se encontraron coincidencias para: ' + _vm.search,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-toolbar-title',{staticClass:"mx-4 white--text"},[_vm._v("Proveedores")]),_c('v-text-field',{staticClass:"mx-4 rounded-lg",staticStyle:{"max-height":"42px","max-width":"400px"},attrs:{"label":"Buscar...","dense":"","flat":"","solo-inverted":"","dark":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.Password",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"rounded":"","color":"teal","elevation":"2","small":"","dark":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v("Generar")])]}},{key:"item.Estatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.Estatus === 'Validado' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.Estatus)+" ")])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('v-data-table',{staticClass:"elevation-1 pa-0 ma-0",attrs:{"headers":_vm.contratistaHeaders,"items":item.contratistas,"dense":"","footer-props":{
        'items-per-page-text': 'Elementos por página',
        'page-text': '{0}-{1} de {2}',
    },"no-data-text":"No hay registros","no-results-text":'No se encontraron coincidencias para: ' + _vm.search},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.estatus === 'Validado' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.estatus)+" ")])]}}],null,true)})],1)],1)]}}])}),_c('DialogComponent',{attrs:{"showDialog":_vm.showDialog,"providerData":_vm.selectedProvider},on:{"update:showDialog":function($event){_vm.showDialog=$event},"update:show-dialog":function($event){_vm.showDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }