<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" style="display:flex; justify-content:center;">
        <v-card
          elevation="0"
          max-width="650"
          class="rounded-lg mt-4"
          style="border: 1px solid #1565C0"
        >
          <v-card-title class="primary white--text subtitle-1 pa-4">
            Selecciona las fechas entre las que se mostrará la asistencia de tu
            equipo.
          </v-card-title>
          <v-card-text
            class="pb-0"
            style="display: flex; justify-content: center;"
          >
            <v-text-field
              v-model="dateRangeText"
              placeholder="Selecciona el rango de fechas"
              style="max-width: 350px;"
              label="Selecciona el rango de fechas"
              prepend-icon="mdi-calendar"
              readonly
              @click="dialogFechas = true"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-0">
            <v-btn
              class="rounded-lg rounded-t-0 font-weight-bold"
              depressed
              color="primary"
              text
              x-large
              block
              dark
              @click="filtrar()"
            >
              <v-icon left>
                mdi-filter
              </v-icon>
              Filtrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      class="mt-3 mx-10 rounded-lg elevation-0"
      style="border: 1px solid #1565C0"
    >
      <v-data-table
        dense
        :items="solicitudes"
        :headers="headers"
        :loading="loadingData"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
        no-data-text="No hay registros"
        loading-text="Cargando información..."
        :no-results-text="
          'No se encontró ningun resultado que coincida con ' + search
        "
        :header-props="headerProps"
        :mobile-breakpoint="0"
        item-key="id_independiente"
      >
        <template v-slot:top>
          <div
            style="display: flex; justify-content: space-between;"
            class="primary pa-3 rounded-lg rounded-b-0"
          >
            <v-text-field
              v-model="search"
              dense
              outlined
              dark
              class="rounded-pill"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar..."
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <vue-json-to-csv
                :json-data="solicitudes"
                :labels="headers_to_export_zn"
                :csv-title="'ReporteSolicitudesNominasZentric'"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      class="rounded-pill mr-2"
                      color="white"
                      dark
                      fab
                      small
                      outlined
                      :loading="loadingData"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-alpha-z</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV zentric</span>
                </v-tooltip>
              </vue-json-to-csv>
              <vue-json-to-csv
                :json-data="solicitudes"
                :labels="headers_to_export"
                :csv-title="'ReporteSolicitudesNominas'"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      class="rounded-pill mr-2"
                      color="white"
                      dark
                      fab
                      small
                      outlined
                      :loading="loadingData"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    class="rounded-pill mr-2"
                    color="white"
                    dark
                    fab
                    small
                    outlined
                    :loading="loadingData"
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizarTabla"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item.fecha="{ item }">
          {{ setFormatDate(item.fecha) }}
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            depressed
            :color="getColorEstatus(item.estatus)"
            class="font-weight-bold"
          >
            {{ setEstatusText(item.estatus) }}
          </v-chip>
        </template>
        <template v-slot:item.fecha_registro="{ item }">
          {{ setFormatDateTime(item.fecha_registro) }}
        </template>
        <template v-slot:item.sueldo_diario="{ item }">
          <label class="font-italic">{{ formatMXN(item.sueldo_diario) }}</label>
        </template>
        <template v-slot:item.monto="{ item }">
          <label class="font-weight-bold font-italic">{{
            formatMXN(item.monto)
          }}</label>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogFechas" max-width="450px">
      <v-date-picker
        v-model="dates"
        max-width="450px"
        v-on:change="cierraDialog()"
        selected-items-text="Seleccionados"
        range
        elevation="1"
        locale="es-mx"
      ></v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import moment from "moment/moment";

export default {
  name: "tabla-solicitudes-descanso-laborado",
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      headers: [
        {
          value: "id_independiente",
          text: "ID",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "emplid",
          text: "emplid",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "empleado",
          text: "empleado",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "fecha",
          text: "fecha",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "sueldo_diario",
          text: "sueldo",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "concepto",
          text: "concepto",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "monto",
          text: "monto",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "no_solicitud",
          text: "solicitud",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "responsable",
          text: "responsable",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "deptoDesc",
          text: "deptoDesc",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "fecha_registro",
          text: "registro",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "estatus",
          text: "estatus",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
      ],
      headers_to_export: {
        id: { title: "id" },
        emplid: { title: "emplid" },
        empleado: { title: "empleado" },
        sueldo_diario: { title: "sueldo_diario" },
        concepto: { title: "concepto" },
        monto: { title: "monto" },
        no_solicitud: { title: "no_solicitud" },
        fecha: { title: "fecha" },
        responsable: { title: "responsable" },
        deptoDesc: { title: "deptoDesc" },
        fecha_registro: { title: "fecha_registro" },
        estatus: { title: "estatus" },
      },
      headers_to_export_zn: {
        emplid: { title: "Trabajador" },
        concepto: { title: "Concepto" },
        tipo_nomina: { title: "Nomina" },
        fecha: { title: "Fecha" },
        monto_zn: { title: "Monto" },
        unidad_zn: { title: "Dato" },
        aplicado_zn: { title: "Aplicado" },
      },
      headerProps: {
        sortByText: "Ordenar por",
      },
      solicitudes: [],
      dates: [],
      expanded: [],
      emplAux: {},
      search: "",
      idSolicitud: null,
      dialogFechas: false,
      muestraColaboradores: false,
      muestraFiguras: false,
      loadingData: true,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    await this.mostrarsemana(this.getWeekNumber());
    this.solicitudes = await this.getSolicitudes();
  },
  computed: {
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  methods: {
    mostrarsemana(semana) {
      const ahora = moment();
      let primerdia = moment()
        .isoWeek(semana)
        .startOf("isoweek");
      let ultimodia = moment()
        .isoWeek(semana)
        .endOf("isoweek");
      let LUNES = primerdia.format("YYYY-MM-DD");
      let DOMINGO = ultimodia.format("YYYY-MM-DD");

      //comprobar si es lunes.
      if (ahora.day() == 1) {
        //comprobar si es antes de las 11:10.
        const horalimite = moment("11:10 AM", "h:mm A");
        if (ahora.isAfter(horalimite)) {
          this.dates.push(LUNES);
          this.dates.push(DOMINGO);
        } else {
          //Mostrar la semana anterior
          let lunesAnterior = moment(LUNES)
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          this.dates[0] = lunesAnterior;
        }
      } else {
        this.dates.push(LUNES);
        this.dates.push(DOMINGO);
      }
    },
    getWeekNumber(date = new Date()) {
      // Copiamos la fecha para no modificar la original
      const currentDate = new Date(date.getTime());

      // Establecemos el primer día de la semana como lunes
      const day = currentDate.getDay();
      const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
      const firstDayOfYear = new Date(currentDate.setDate(diff));

      // Calculamos el día del año
      const start = new Date(currentDate.getFullYear(), 0, 1);
      const dayOfYear = (firstDayOfYear - start + 86400000) / 86400000;

      // Calculamos el número de la semana
      return Math.ceil(dayOfYear / 7);
    },
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async actualizarTabla() {
      this.loadingData = true;
      this.solicitudes = await this.getSolicitudes();
    },
    getSolicitudes() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/reporte-solicitudes/${this.dates[0]}/${this.dates[1]}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    setFormatDateTime(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      let horasUTC = fecha.getUTCHours();
      let minutosUTC = fecha.getUTCMinutes();
      let segundosUTC = fecha.getUTCSeconds();

      // Formateo de día, mes, horas, minutos y segundos con ceros iniciales si es necesario
      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;
      horasUTC = horasUTC < 10 ? "0" + horasUTC : horasUTC;
      minutosUTC = minutosUTC < 10 ? "0" + minutosUTC : minutosUTC;
      segundosUTC = segundosUTC < 10 ? "0" + segundosUTC : segundosUTC;

      return `${diaUTC}/${mesUTC}/${anioUTC} ${horasUTC}:${minutosUTC}:${segundosUTC}`;
    },
    setEstatusText(estatus) {
      switch (estatus) {
        case 0:
          return "Abierta";
        case 2:
          return "Autorizada";
        case 3:
          return "Rechazada";
        default:
          return "No disponible";
      }
    },
    getColorEstatus(estatus) {
      switch (estatus) {
        case 0:
          return "yellow lighten-4";
        case 2:
          return "green lighten-3";
        case 3:
          return "red lighten-3";
        default:
          return "blue-grey lighten-3";
      }
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    filtrar() {
      if (this.dates.length == 2) {
        this.actualizarTabla();
      } else {
        this.showSnackBar("Error. Selecciona fechas de inicio y fin");
      }
    },
    cierraDialog() {
      if (this.dates.length == 2) {
        this.dialogFechas = false;
      }
    },
  },
};
</script>
