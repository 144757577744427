<template>
    <div>
        <v-data-table :headers="proveedorHeaders" :items="proveedores" :expanded.sync="expanded" item-key="No_proveedor"
            :search="search" :footer-props="{
            'items-per-page-text': 'Elementos por página',
            'page-text': '{0}-{1} de {2}',
        }" no-data-text="No hay registros" :no-results-text="'No se encontraron coincidencias para: ' + search" show-expand
            class="elevation-1">
            <template v-slot:top>
                <div style="display: flex; justify-content: space-between;"
                    class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
                    <v-toolbar-title class="mx-4 white--text">Proveedores</v-toolbar-title>
                    <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                        append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
                        class="mx-4 rounded-lg"></v-text-field>
                </div>
            </template>

            <template v-slot:item.Password="{ item }">
                <v-btn rounded @click="openDialog(item)" color="teal" elevation="2" small dark>Generar</v-btn>
            </template>

            <template v-slot:item.Estatus="{ item }">
                <v-chip :color="item.Estatus === 'Validado' ? 'green' : 'red'" dark>
                    {{ item.Estatus }}
                </v-chip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                    <v-card class="pa-0 ma-0">
                        <v-data-table :headers="contratistaHeaders" :items="item.contratistas" class="elevation-1 pa-0 ma-0"
                            dense :footer-props="{
            'items-per-page-text': 'Elementos por página',
            'page-text': '{0}-{1} de {2}',
        }" no-data-text="No hay registros" :no-results-text="'No se encontraron coincidencias para: ' + search">
                            <template v-slot:item.estatus="{ item }">
                                <v-chip :color="item.estatus === 'Validado' ? 'green' : 'red'" dark>
                                    {{ item.estatus }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-card>
                </td>
            </template>
        </v-data-table>

       
        <DialogComponent :showDialog.sync="showDialog" :providerData="selectedProvider" />
    </div>
</template>

<script>
import axios from 'axios';
import DialogComponent from './DialogPassword.vue';

export default {
    components: {
        DialogComponent
    },
    mounted() {
        this.getProveedores();
        this.getColaboradores();
    },
    data() {
        return {
            search: '',
            expanded: [],
            showDialog: false,
            selectedProvider: {},
            proveedorHeaders: [
                { text: '', value: 'data-table-expand', class: "overline font-weight-bold primary white--text rounded-right-top-table-lg" },
                { text: 'NO.PROVEEDOR', value: 'No_proveedor', class: 'font-weight-bold primary white--text' },
                { text: 'NOMBRE', value: 'Nombre', class: 'font-weight-bold primary white--text' },
                { text: 'CORREO', value: 'Correo', class: 'font-weight-bold primary white--text' },
                { text: 'RFC', value: 'Rfc', class: 'font-weight-bold primary white--text' },
                { text: 'NICKNAME', value: 'Nickname', class: 'font-weight-bold primary white--text' },
                { text: 'PASSWORD', value: 'Password', class: 'font-weight-bold primary white--text' },
                { text: 'ESTATUS', value: 'Estatus', class: 'font-weight-bold primary white--text' }
            ],
            contratistaHeaders: [
                { text: 'NOMBRE', value: 'nombre', class: 'font-weight-bold primary white--text' },
                { text: 'TELEFONO', value: 'telefono', class: 'font-weight-bold primary white--text' },
                { text: 'FUNCIÓN', value: 'funcion', class: 'font-weight-bold primary white--text' },
                {
                    text: 'ESTATUS', value: 'estatus', class: 'font-weight-bold primary white--text',
                    formatter: (estatus) => estatus === 1 ? 'Validado' : 'Rechazado'
                }
            ],
            proveedores: [],
            colaboradoresPorProveedor: {}
        };
    },
    methods: {
        async getProveedores() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getProveedores`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.proveedores = response.data.data.map(proveedor => ({
                    id: proveedor.id,
                    Nombre: proveedor.Nombre,
                    No_proveedor: proveedor.No_Proveedor,
                    Rfc: proveedor.Rfc,
                    Correo: proveedor.Correo,
                    Nickname: proveedor.Nickname,
                    Password: proveedor.Password,
                    Estatus: proveedor.Estatus === 1 ? 'Validado' : 'Rechazado',
                    contratistas: this.colaboradoresPorProveedor[proveedor.No_Proveedor] || []
                }));
            } catch (error) {
                console.error('Error al cargar proveedores:', error);
            }
        },
        async getColaboradores() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getColaboradoresTabla`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                const colaboradoresAgrupados = response.data.data.reduce((acc, colaborador) => {
                    const noProveedor = colaborador.No_proveedor;
                    if (!acc[noProveedor]) {
                        acc[noProveedor] = [];
                    }
                    acc[noProveedor].push({
                        nombre: colaborador.Nombre,
                        telefono: colaborador.Telefono,
                        funcion: colaborador.Funcion,
                        estatus: colaborador.Estatus === 1 ? 'Validado' : 'Rechazado'
                    });

                    return acc;
                }, {});

                this.colaboradoresPorProveedor = colaboradoresAgrupados;
                this.getProveedores();

            } catch (error) {
                console.error('Error al cargar colaboradores:', error);
            }
        },
        openDialog(item) {
            this.selectedProvider = item;
            this.showDialog = true;
            console.log(item.Password)
        },
        generateText(item) {
            console.log('item', item);
            let text = '';
            let hasSpecialChar = false;
            let hasNumber = false;
            const minLength = 8;
            const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$*%#';

            while (text.length < minLength || !hasSpecialChar || !hasNumber || !/^[a-zA-Z]/.test(text)) {
                text = '';
                hasSpecialChar = false;
                hasNumber = false;
                for (let i = 0; i < minLength; i++) {
                    const char = validChars.charAt(Math.floor(Math.random() * validChars.length));
                    text += char;
                    if (!hasSpecialChar && '@$*%#'.includes(char)) {
                        hasSpecialChar = true;
                    }
                    if (!hasNumber && '0123456789'.includes(char)) {
                        hasNumber = true;
                    }
                }
            }

            return text;
        },
    }
};
</script>
