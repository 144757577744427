<template>
  <v-dialog
    v-model="muestraMotivos"
    content-class="fix-border-dialog"
    persistent
    max-width="500"
    scrollable
  >
    <v-card class="rounded-xl" height="auto">
      <v-card-title class="primary">
        <label class="white--text overline subtitle-1 font-weight-bold">
          ¿Cuál es el motivo del registro?
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraMotivos', null)">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formMotivosTE">
            <label class="overline"
              >motivo<strong :class="inputRequired">*</strong></label
            >
            <v-select
              :items="motivos"
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="motivoseleccionado"
              item-text="descripcion"
              item-value="id"
              outlined
              required
              :rules="rulesSelect"
              dense
              class="rounded-lg"
              placeholder="Seleccione el motivo principal"
            >
            </v-select>
            <div v-show="[1, 2].includes(motivoseleccionado)">
              <!-- {{ idsFifuras }} -->
              <v-card
                v-show="idsFifuras.length > 0"
                class="rounded-lg py-1 px-3 elevation-0"
                style="border: 0.5px solid #CFD8DC;"
              >
                <label
                  class="caption text-uppercase blue-grey--text text--lighten-2"
                >
                  figuras seleccionadas
                </label>
                <v-row dense>
                  <v-col
                    v-for="(id, index) in idsFifuras"
                    :key="index"
                    cols="auto"
                  >
                    <v-chip small @click="removeFigura(id)">
                      {{ id.item }}
                      <v-icon right x-small color="blue-grey darken-1"
                        >mdi-close-thick</v-icon
                      >
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card>

              <!-- {{ figurasSeleccionadas }} -->
              <label class="overline">Figura(s) a elaborar</label>

              <v-autocomplete
                v-model="figurasSeleccionadas"
                :items="figurasEncontradas"
                @change="setFigura"
                color="blue-grey lighten-2"
                outlined
                dense
                class="rounded-lg"
                no-data-text="Ingrese al menos 5 caracteres para comenzar a buscar."
                placeholder="Busca por ID o nombre"
                item-text="item_search"
                item-value="item"
                :disabled="loadingFiguras"
                :loading="loadingFiguras"
                :search-input.sync="searchFiguras"
              >
              </v-autocomplete>
              <v-alert
                v-model="alertFigurasAgregadas"
                class="rounded-lg"
                icon="mdi-alert-circle"
                dense
                border="left"
                type="error"
                dismissible
              >
                Para el motivo seleccionado es necesario agreguer al menos una
                figura.
              </v-alert>
            </div>
            <div v-if="motivoseleccionado == 2">
              <v-row dense>
                <v-col cols="6">
                  <label class="overline"
                    >Mínimo<strong :class="inputRequired">*</strong></label
                  >
                  <v-text-field
                    type="number"
                    placeholder="Ingrese el valor mínimo"
                    v-model="minimo"
                    outlined
                    dense
                    :required="motivoseleccionado == 2 ? true : false"
                    :rules="motivoseleccionado == 2 ? rulesInputInt : []"
                    class="rounded-lg"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <label class="overline"
                    >máximo<strong :class="inputRequired">*</strong></label
                  >
                  <v-text-field
                    type="number"
                    placeholder="Ingrese el valor máximo"
                    v-model="maximo"
                    outlined
                    dense
                    :required="motivoseleccionado == 2 ? true : false"
                    :rules="motivoseleccionado == 2 ? rulesInputInt : []"
                    class="rounded-lg"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="motivoseleccionado == 3">
              <label class="overline"
                >monto<strong :class="inputRequired">*</strong></label
              >
              <v-text-field
                type="number"
                placeholder="Ingrese el monto"
                prefix="$"
                v-model="monto"
                :required="motivoseleccionado == 3 ? true : false"
                :rules="motivoseleccionado == 3 ? rulesInputInt : []"
                outlined
                dense
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div v-else-if="motivoseleccionado == 4">
              <label class="overline">área</label>
              <v-select
                :items="areas"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="areamotivo"
                item-text="desc"
                item-value="id"
                :required="motivoseleccionado == 4 ? true : false"
                :rules="motivoseleccionado == 4 ? rulesSelect : []"
                placeholder="Selecciona el área"
                outlined
                dense
                class="rounded-lg"
              >
              </v-select>
            </div>
            <label class="overline">
              Responsable del motivo seleccionado
              <strong :class="inputRequired">*</strong>
            </label>
            <v-autocomplete
              v-model="valuesrespmotivo"
              :items="responsablemotivo"
              item-text="responsable"
              item-value="responsable"
              outlined
              dense
              :required="motivoseleccionado != 1 ? true : false"
              :rules="motivoseleccionado != 1 ? rulesSelect : []"
              class="rounded-lg"
              label="Seleccione al responsable del motivo"
              prepend-inner-icon="mdi-account-arrow-left"
            ></v-autocomplete>
            <label class="overline"
              >justificación<strong :class="inputRequired">*</strong></label
            >
            <v-textarea
              outlined
              dense
              class="rounded-lg"
              label="Ingrese una justificación..."
              required
              :rules="rulesInputComment"
              rows="2"
              counter="150"
              prepend-inner-icon="mdi-comment"
              v-model="comentario"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="rounded-xl rounded-t-0 font-weight-bold"
          block
          x-large
          text
          color="primary"
          @click="validaFormulario"
        >
          Continuar
        </v-btn>
      </v-card-actions>

      <SnackBar
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  props: ["muestraMotivos"],
  components: { SnackBar },
  data() {
    return {
      motivos: [],
      responsablemotivo: [],
      figurasEncontradas: [],
      idsFifuras: [],
      rulesSelect: [(v) => !!v || "Este campo es requerido"],
      rulesInput: [(v) => !!v || "Este campo es requerido"],
      rulesInputComment: [
        (v) => !!v || "Este campo es requerido",
        (v) =>
          (v && v.length <= 150) || "No puedes agregar más de 150 caracteres.",
      ],
      rulesInputInt: [
        (v) => !!v || "Este campo es requerido",
        (v) => v > 0 || "No puedes introducir un valor menor a 1",
      ],
      figurasSeleccionadas: null,
      areas: [
        { id: 1, desc: "Desarrollo Humano" },
        { id: 2, desc: "Administracion y Finanzas" },
        { id: 6, desc: "Logistica" },
        { id: 7, desc: "Operaciones" },
        { id: 9, desc: "Ventas Nacionales" },
        { id: 10, desc: "Mercadotecnia" },
        { id: 12, desc: "Ventas Internacionales" },
        { id: 11, desc: "Innovacion y competitividad" },
      ],
      maximo: null,
      minimo: null,
      monto: null,
      comentario: null,
      valuesrespmotivo: null,
      motivoseleccionado: null,
      areamotivo: null,
      loadingFiguras: false,
      alertFigurasAgregadas: false,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
      searchFiguras: "",
      inputRequired: "pink--text text--darken-2",
    };
  },
  async mounted() {
    this.motivos = await this.getMotivos();
    this.responsablemotivo = await this.getResponsablesMotivos();
  },
  watch: {
    searchFiguras(val) {
      this.filterItems(val);
    },
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async filterItems(search) {
      if (search) {
        if (search.length > 4) {
          this.loadingFiguras = true;
          this.figurasEncontradas = await this.getFigurasJde(search);
        }
      } else {
        this.figurasEncontradas = [];
      }
    },
    async setFigura() {
      const indice = await this.figurasEncontradas.findIndex(
        (objeto) => objeto.item === this.figurasSeleccionadas
      );
      this.idsFifuras.push(this.figurasEncontradas[indice]);
      this.searchFiguras = "";
      this.figurasEncontradas = [];
    },
    async removeFigura(figura) {
      const indice = await this.idsFifuras.findIndex(
        (objeto) => objeto === figura
      );
      this.idsFifuras.splice(indice, 1);
    },
    getMotivos() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            if (response.data.status.trim() == "EXPSESSION") {
              deleteToken();
            } else {
              var data = response.data.motivos;
              if (data) {
                res(data);
              } else {
                res([]);
              }
            }
            this.cargando = false;
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      });
    },
    getResponsablesMotivos() {
      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            if (response.data.status.trim() == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.respmotivos);
            }
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      });
    },
    getFigurasJde(entrada) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/figuras-jde/${entrada}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingFiguras = false;
          });
      });
    },
    async validaFormulario() {
      if (this.$refs.formMotivosTE.validate()) {
        if (
          [1, 2].includes(this.motivoseleccionado) &&
          this.idsFifuras.length <= 0
        ) {
          this.showSnackBar(
            "Es necesario llenar toda la información solicitada."
          );
        } else {
          const indexMotivo = this.motivos.findIndex(
            (m) => (m.id = this.motivoseleccionado)
          );
          this.$emit("cierraMotivos", {
            motivo: this.motivoseleccionado,
            motivoDesc: this.motivos[indexMotivo].descripcion,
            figuras: this.idsFifuras,
            justificacion: this.comentario,
            minimo: this.minimo,
            maximo: this.maximo,
            responsableSeleccionado: this.valuesrespmotivo,
            monto: this.monto,
            area: this.areamotivo,
          });
        }
      } else {
        this.showSnackBar(
          "Es necesario llenar toda la información solicitada."
        );
      }
    },
    // setSolicitudIncidencia(){
    //   const params = {
    //     colaboradores: this.col
    //   }
    // }
  },
};
</script>
