<template>
  <v-dialog max-width="600" v-model="dialogUpload" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        Cargar documento
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraUpload', null)">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-file-input v-model="file" show-size accept=".pdf" label="Entrada de archivo"></v-file-input>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" elevation="2" @click="submitFile()" :loading="loadingBotonGuardar">Cargar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: "carga-archivo",
  props: ["dialogUpload", "fileName", "fileType"],
  data() {
    return {
      numProv: 111111,
      file: null,
      loadingBotonGuardar: false,
      encryptionKey: null,
      iv: null,
      encryptedData: null,
    };
  },
  methods: {
    async submitFile() {
      if (!this.file) {
        console.error('No hay archivo seleccionado');
        return;
      }
      this.loadingBotonGuardar = true;
      try {
        const fileArrayBuffer = await this.readFileAsArrayBuffer(this.file);
        const { encryptData, iv, key } = await this.encrypt(fileArrayBuffer);
        const idColaborador = 82;
        this.iv = iv;
        this.encryptionKey = key;
        this.encryptedData = encryptData;
        const encryptedFile = new Blob([encryptData], { type: 'application/octet-stream' });
        const formData = new FormData();
        formData.append('encryptedFile', encryptedFile, `${this.fileName}`);
        formData.append('iv', JSON.stringify(Array.from(iv)));
        formData.append('key', JSON.stringify(await this.exportKey(key)));
        formData.append('id', idColaborador);
        formData.append('tipo', this.fileType);
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/cargarArchivo/${this.numProv}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem("token"),
          },
        });

        if(response.status){
          this.$emit('cierraUpload', true);
          console.log('Archivo cifrado enviado al backend:', response.data);
        }else{
          this.$emit('cierraUpload', false);
          console.log('Archivo cifrado enviado al backend:', response.data);
        }

        
      } catch (error) {
        console.error('Error al cifrar y enviar el archivo:', error);
      } finally {
        this.loadingBotonGuardar = false;
        this.file = null;
        this.$emit('cierraUpload', null);
      }
    },

    async encrypt(data) {
      try {
        const key = await crypto.subtle.generateKey(
          {
            name: 'AES-GCM',
            length: 256,
          },
          true,
          ['encrypt', 'decrypt']
        );

        const iv = crypto.getRandomValues(new Uint8Array(12));
        const encryptData = await crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          data
        );

        return { encryptData, iv, key };
      } catch (error) {
        console.error('Error al cifrar los datos:', error);
        throw error;
      }
    },

    async exportKey(key) {
      const exported = await crypto.subtle.exportKey('jwk', key);
      return exported;
    },

    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
};
</script>
