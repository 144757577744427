<template>
    <v-container>
      
      <v-card class="v-lazy pa-4" style="min-height: 52px">
        <v-tabs v-model="tab" color="primary" slider-color="primary">
          <v-tab>
            Datos Patronales
          </v-tab>
          <v-tab>
            Colaboradores
          </v-tab>
          <v-tab>
           Proveedores
          </v-tab>
          <v-tab-item>
            <FormularioDatosPatronales />
          </v-tab-item>
          <v-tab-item>
           <TablaColaboradores/>
          </v-tab-item>
          <v-tab-item>
            <AdmonProveedores/>
           </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import TablaColaboradores from './TablaColaboradores.vue'
  import FormularioDatosPatronales from './FormularioDatosPatronales.vue';
 
  import AdmonProveedores from './AdmonProveedores.vue';

  
  export default {
    components: {
      FormularioDatosPatronales,
      TablaColaboradores,
     
      AdmonProveedores

     
    },
    data: () => ({
      tab: 0
    }),
  };
  </script>
  
  