<template>
  <v-dialog v-model="muestraFormulario" fullscreen scrollable>

    <v-card class="rounded-0">
      <v-card-title class="primary">
        <label class="overline font-weight-bold white--text">Registro de visita</label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraForm', false)">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
    <v-form v-model="valid" ref="formVisits">
      <v-card elevation="0" class="mx-4 mt-4 rounded-xl pa-5">
        <label
          v-show="muestraMensajeSinPrivilegios"
          class="caption blue-grey--text text--lighten-2"
        >
          * Como parte de nuestros procesos de seguridad corporativa, los
          ingresos de proveedores que <strong>no cuentan con un alta en JDE y reqeuiras
          que ingresen a generar cotizaciones o levantamientos de proyectos,</strong>
          solo podrán ser registrados en una sola ocasión. Si requieres de
          alguna excepción extraordinaria para el ingreso de más uno, envía
          correo de requerimiento a vigilancia@urrea.net, jsalinas@urrea.net<br>
          <strong
            >Solicitudes 'cliente/cotización' de hoy:
            {{ privilegios.visitasRegistradas }}. </strong
          ><br />
        </label>
        <label class="overline">Tipo de visita</label>
        <!-- v-on:change="cambioUbicacionPorTipo()" -->
        <v-autocomplete
          v-model="tipo"
          dense
          outlined
          placeholder="Ingresa el tipo de visita"
          required
          :rules="rules.type"
          v-on:change="
            loadingUbicaciones = true;
            loadingAcciones = true;
            getUbicacion();
          "
          :items="tipoVisitas"
          item-text="descr"
          class="rounded-lg"
        >
        </v-autocomplete>
        <div v-show="tipo == 'Proveedor'">
          <label class="overline">Datos del proveedor</label>
          <v-row style="margin-bottom: 2em; ">
            <v-col cols="12" md="8">
              <v-menu
                v-model="menuIsActive"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <!-- <input
                            v-model="material.unidad"
                            v-on="on"
                            type="text"
                            @input="getToolsCatalog(material.unidad)"
                          /> -->
                  <v-text-field
                    v-on="on"
                    v-model="nombreCompania"
                    @input="
                      loadingNombreCompania = true;
                      getProveedores();
                    "
                    dense
                    outlined
                    class="rounded-lg"
                    label="Razón social de la compañía"
                    placeholder="Busca por nombre de compañía"
                    v-on:change="buscaProveedorTemporal()"
                  ></v-text-field>
                </template>
                <v-card>
                  <div
                    v-if="
                      nombreCompania.length >= 3 && proveedores.length === 0
                    "
                    class="center-item-in-div py-5"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <v-list v-else-if="proveedores.length > 0" dense>
                    <v-list-item
                      v-for="item in proveedores"
                      :key="item.NOMBRE"
                      dense
                      class="body-2 text-uppercase font-weight-bold rounded-pill"
                      @click="selectItem(item)"
                    >
                      {{ item.NOMBRE }}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>

              <!-- <v-card
                v-if="proveedores.length > 0 || loadingNombreCompania"
                class="rounded-xl px-2 blue-grey lighten-5 elevation-0"
              >
                <v-list
                  v-if="!loadingNombreCompania"
                  class="rounded-xl transparent"
                >
                  <v-list-item
                    v-for="item in proveedores"
                    :key="item.NOMBRE"
                    dense
                    class="body-2 text-uppercase font-weight-bold rounded-pill"
                    @click="selectItem(item)"
                  >
                    {{ item.NOMBRE }}
                  </v-list-item>
                </v-list>
                <div v-else class="py-5 center-item-in-div">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card> -->
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="nPermisoSTPS"
                :counter="50"
                label="Numero de permiso STPS"
                dense
                outlined
                class="rounded-lg"
                required
                disabled
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="rfc"
                label="RFC"
                dense
                outlined
                class="rounded-lg"
                required
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="correoProveedor"
                type="mail"
                label="Correo proveedor"
                dense
                outlined
                class="rounded-lg"
                required
                :rules="tipo == 'Proveedor' ? rules.emailRules : []"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mx-15 my-2"></v-divider>
        <v-col cols="12" md="12">
          <label class="overline">Trabajo a realizar</label>
          <div v-if="tipo == 'Proveedor'">
            <v-row dense>
              <v-col cols="12" md="3">
                <v-radio-group v-model="accion" :disabled="esProveedorTemporal">
                  <v-radio
                    :value="0"
                    label="Recolección de material *"
                  ></v-radio>
                  <v-radio :value="1" label="Entrega de material *"></v-radio>
                  <v-radio :value="3" label="Trabajos en planta"></v-radio>
                  <v-radio
                    v-show="tienePrivilegios"
                    :value="4"
                    label="Cotización"
                  ></v-radio>
                  <v-radio :value="5" v-show="['711-113','717-117','900-148', '712-115'].includes(privilegios.DEPTID)" label="Servicios"></v-radio>
                  <v-radio v-show="[6, 11].includes(privilegios.Id_Area)" :value="6" label="In-House"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="9">
                <label class="overline">Descripción de motivo de visita </label>
                <v-select
                  v-model="actionSelected"
                  :items="
                    acciones.filter(
                      (item) => item.tipo_accion_proveedor == accion
                    )
                  "
                  :loading="loadingAcciones"
                  item-text="descr"
                  outlined
                  dense
                  required
                  :rules="rules.type"
                  class="rounded-lg"
                  placeholder="Selecciona la acción..."
                  :disabled="
                    acciones.filter(
                      (item) => item.tipo_accion_proveedor == accion
                    ).length === 0 || esProveedorTemporal
                      ? true
                      : false
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" md="12">
                <label class="overline">¿Se realizará trabajo de riesgo?</label>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <div class="center-item-in-div">
                      <v-switch
                        class="my-2 pa-0"
                        v-model="realizaraTrabajoDeRiesgo"
                        style="max-height: 24px !important;"
                        :disabled="esProveedorTemporal"
                      ></v-switch>
                      <div class="body-2 ml-2">
                        {{ realizaraTrabajoDeRiesgo ? "Sí" : "No" }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      v-model="accionDeRiesgo"
                      item-text="descripcion"
                      class="rounded-lg"
                      placeholder="Selecciona el trabajo a realizar..."
                      dense
                      outlined
                      :loading="loadingTrabajosDeRiesgo"
                      :items="trabajosDeRiesto"
                      :disabled="
                        !realizaraTrabajoDeRiesgo || esProveedorTemporal
                      "
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-select
              v-model="actionSelected"
              :items="acciones"
              :loading="loadingAcciones"
              item-text="descr"
              outlined
              dense
              class="rounded-lg"
              placeholder="Selecciona la acción..."
              :disabled="
                acciones.length === 0 || esProveedorTemporal ? true : false
              "
            ></v-select>
          </div>
        </v-col>
        <!-- {{"bills: " + bils.length}} {{ passes }} -->
        <v-col v-if="accion === 1 && tipo == 'Proveedor'" cols="12">
          <v-card
            class="rounded-lg elevation-0"
            width="100%"
            color="blue-grey lighten-5"
          >
            <v-card-title
              class="caption font-italic blue-grey--text text--darken-2"
            >
              * Para registrar la visita de un proveedor que te entregará
              material, es necesario que adjuntes evidencia de las facturas.
            </v-card-title>
            <v-card-text>
              <div class="center-item-in-div-column">
                <label class="overline"
                  >Selecciona el documento (MÁX. 3MB)</label
                >
                <v-file-input
                  v-model="fileData"
                  v-on:change="addDocument"
                  outlined
                  dense
                  counter
                  show-size
                  placeholder="Selecciona el documento..."
                  style="width: 400px;"
                  class="rounded-lg"
                ></v-file-input>
              </div>

              <v-sheet
                style="display: flex; justify-content: start;"
                class="pa-5 rounded-xl"
                color="blue-grey lighten-4"
                v-if="bills.length > 0"
              >
                <v-card
                  class="rounded-lg pa-3 mx-2 center-item-in-div-column elevation-0"
                  width="135"
                  height="135"
                  v-for="(item, index) in bills"
                  :key="index"
                >
                  <label class="text-uppercase subtitle-2 font-weight-black">
                    {{ item.type }}
                  </label>
                  <v-icon x-large>
                    {{
                      item.type === "pdf"
                        ? "mdi-file-document-check"
                        : "mdi-image-check"
                    }}
                  </v-icon>
                  <label
                    class="overline text-truncate"
                    style="max-width: 120px;"
                    >{{ item.name }}</label
                  >
                </v-card>
              </v-sheet>
            </v-card-text>
          </v-card>
          <label class="overline font-weight-bold"
            >Documentos cargados con éxito: {{ bills.length }}</label
          >
        </v-col>
        <v-col v-else-if="accion === 0 && tipo == 'Proveedor'" cols="12">
          <v-card
            class="rounded-lg elevation-0"
            width="100%"
            color="blue-grey lighten-5"
          >
            <v-card-title
              class="caption font-italic blue-grey--text text--darken-2"
            >
              * Para registrar la visita de un proveedor que recogerá material,
              es necesario que selecciones un pase de salida solicitado por ti y
              que este en estatus autorizado. Si no tienes uno,
              <router-link
                :to="
                  $store.getters.isMultisistema === true
                    ? '/DHGeneral/MiEmpresa/SeguridadPatrimonial/PasesDeSalida'
                    : '/MiEmpresa/SeguridadPatrimonial/PasesDeSalida'
                "
              >
                {{ " " + "solicitalo aquí." }}
              </router-link>
            </v-card-title>
            <v-card-text>
              <div class="center-item-in-div-column">
                <label class="overline">Selecciona el pase de salida</label>
                <!-- <v-file-input
                  v-model="fileData"
                  v-on:change="addDocument"
                  outlined
                  dense
                  counter
                  show-size
                  placeholder="Selecciona el documento..."
                  style="width: 400px;"
                  class="rounded-lg"
                ></v-file-input> -->

                <v-autocomplete
                  v-model="paseDeSalidaSeleccionado"
                  outlined
                  dense
                  counter
                  show-size
                  placeholder="Selecciona el documento..."
                  style="width: 400px;"
                  class="rounded-lg"
                  :items="pasesDeSalida"
                  item-text="nombreMostrar"
                  :loading="loadingPasesDeSalida"
                >
                </v-autocomplete>
              </div>

              <v-sheet
                style="display: flex; justify-content: start;"
                class="pa-5 rounded-xl"
                color="blue-grey lighten-4"
                v-if="passes.length > 0"
              >
                <v-card
                  class="rounded-lg pa-3 mx-2 center-item-in-div-column elevation-0"
                  width="135"
                  height="135"
                  v-for="(item, index) in passes"
                  :key="index"
                >
                  <label class="text-uppercase subtitle-2 font-weight-black">
                    {{ item.type }}
                  </label>
                  <v-icon x-large>
                    {{
                      item.type === "pdf"
                        ? "mdi-file-document-check"
                        : "mdi-image-check"
                    }}
                  </v-icon>
                  <label
                    class="overline text-truncate"
                    style="max-width: 120px;"
                    >{{ item.name }}</label
                  >
                </v-card>
              </v-sheet>
            </v-card-text>
          </v-card>
          <!-- <label class="overline font-weight-bold"
            >Documentos cargados con éxito: {{ passes.length }}</label
          > -->
        </v-col>
        <v-divider class="mx-15 my-2"></v-divider>
        <label class="overline">Fecha de visita</label>
        <v-row dense>
          <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 3">
            <label>Frecuencia</label>
            <v-radio-group v-model="frecuencia">
              <v-radio
                v-for="frec in frecuencias"
                :key="frec.id"
                :label="`${frec.mensaje}`"
                :value="frec.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 9">
            <v-row dense>
              <v-col :cols="numColumsInDates">
                <v-dialog
                  ref="dialogVisita"
                  v-model="modalFechaVisita"
                  :return-value.sync="fechaDeVisita"
                  width="290px"
                  content-class="fix-border-dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaDeVisita"
                      label="Día de visita"
                      prepend-inner-icon="mdi-calendar"
                      required
                      :rules="rules.date"
                      dense
                      outlined
                      class="rounded-lg"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaDeVisita"
                    :min="fechaBloqueo"
                    :events="functionEvents"
                    :picker-date.sync="fechaDeVisita"
                    class="rounded-xl"
                    scrollable
                    @input="
                      $refs.dialogVisita.save(fechaDeVisita);
                      horariosDisponibles = horariosFijos;
                      modalFechaVisita = false;
                      loadingEntrada = true;
                      deleteHours();
                    "
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col :cols="numColumsInDates">
                <v-autocomplete
                  v-model="horaEntrada"
                  :items="horariosDisponibles"
                  label="hora de entrada"
                  v-on:change="obtenerHorariosDeSalida"
                  outlined
                  class="rounded-lg"
                  required
                  :rules="rules.time"
                  dense
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  :disabled="disabledEntrada"
                  :loading="loadingEntrada"
                ></v-autocomplete>
              </v-col>

              <v-col :cols="numColumsInDates">
                <!-- <v-dialog
                  ref="dialogHoraFinal"
                  v-model="dialogHoraSalida"
                  :return-value.sync="horaSalida"
                  content-class="fix-border-dialog"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }"> -->
                <!-- {{ horaSalida }} -->
                <v-select
                  v-model="horaSalida"
                  label="Duración de la visita"
                  outlined
                  class="rounded-lg"
                  required
                  :items="horariosDeSalida"
                  value="valor"
                  item-text="texto"
                  :rules="rules.time"
                  dense
                  :disabled="horaEntrada != '' ? false : true"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                ></v-select>
                <!-- </template>
                  <v-time-picker
                    v-model="horaSalida"
                    format="24hr"
                    class="rounded-xl"
                    full-width
                    @input="$refs.dialogHoraFinal.save(horaSalida)"
                  >
                  </v-time-picker>
                </v-dialog> -->
              </v-col>
              <v-col v-show="frecuencia === 'semanal'" :cols="numColumsInDates">
                <v-dialog
                  ref="dialogFinDeVisita"
                  v-model="modalFechaFinVisita"
                  :return-value.sync="fechaFinDeVisita"
                  width="290px"
                  content-class="fix-border-dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-show="fechaDeVisita == ''"
                      class="caption font-italic blue-grey--text text--lighten-2"
                      >* Es necesario que primero selecciones el día de
                      inicio.</label
                    >
                    <v-text-field
                      v-model="fechaFinDeVisita"
                      label="Día de fin de visita"
                      prepend-inner-icon="mdi-calendar"
                      :required="false"
                      :rules="[validateEndDate]"
                      dense
                      outlined
                      class="rounded-lg"
                      readonly
                      :disabled="fechaDeVisita != '' ? false : true"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFinDeVisita"
                    class="rounded-xl"
                    :max="getFechaMaxima()"
                    scrollable
                    :min="fechaDeVisita"
                    @input="
                      $refs.dialogFinDeVisita.save(fechaFinDeVisita);
                      modalFechaFinVisita = false;
                    "
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mx-15 my-2"></v-divider>
        <label class="overline text-uppercase">Infromación de la visita</label>
        <v-row dense>
          <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 6">
            <v-col cols="12">
              <label class="caption text-uppercase">¿A quién visita?</label>
              <v-autocomplete
                v-model="colaboradorVisitado"
                v-on:change="updatePhoneNumber"
                class="rounded-lg"
                dense
                outlined
                required
                :rules="rules.textField"
                counter="100"
                :items="colaboradores"
                item-text="nombreMostrar"
                :loading="loadingColaboradores"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <label class="caption text-uppercase"
                >Teléfono del responsable</label
              >
              <v-text-field
                v-model="telefonoColaboradorVisitado"
                class="rounded-lg"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 6">
            <label class="caption text-uppercase"
              >Destino de la visita (ubicación)</label
            >
            <v-radio-group
              v-model="ubicacionPrincipal"
              class="text-center"
              v-on:change="cahngeUbicacionPrincipal"
              row
            >
              <v-radio label="El salto" value="Planta el salto"></v-radio>
              <v-radio label="Américas" value="Américas"></v-radio>
              <v-radio label="CD Sur" value="CD Sur"></v-radio>
            </v-radio-group>
            <v-row dense>
              <v-col cols="10">
                <v-select
                  v-model="ubicacionSeleccionada"
                  class="rounded-lg"
                  dense
                  outlined
                  placeholder="Selecciona una opción..."
                  no-data-text="Selecciona un tipo de visita."
                  :disabled="otraUbicacion || ['CD Sur','Américas'].includes(ubicacionPrincipal)"
                  :required="!otraUbicacion"
                  :rules="!otraUbicacion ? rules.select : []"
                  :items="ubicaciones"
                  item-text="Ubicacion"
                  :loading="loadingUbicaciones"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="otraUbicacion"
                  class="mt-1 mr-0"
                  label="Otro"
                ></v-checkbox>
              </v-col>
              <v-col v-show="otraUbicacion" cols="12">
                <v-text-field
                  v-model="otraUbicacionDesc"
                  class="rounded-lg"
                  :required="otraUbicacion"
                  :rules="otraUbicacion ? rules.textField : []"
                  dense
                  outlined
                  label="Escribe el destino de la visita."
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12">
            <v-textarea
              v-model="motivoVisita"
              class="rounded-lg"
              dense
              outlined
              rows="2"
              label="Motivo de la visita"
            ></v-textarea>
          </v-col> -->
        </v-row>
        <v-divider class="mx-15 my-2"></v-divider>
        <label class="overline">Datos personales de visitante(s)</label>
        <div
          v-if="
            tipo !== 'Proveedor' || visitantesPermitidosProveedor.length === 0
          "
        >
          <v-switch
            v-model="visitaGrupal"
            v-on:change="validaVisitaGrupal"
            label="¿Es visita grupal?"
          ></v-switch>
          <v-row dense v-for="(item, index) in datosVisitantes" :key="index">
            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 3">
              <v-text-field
                v-model="item.nombreVisitante"
                :counter="50"
                label="Nombre(s)"
                dense
                outlined
                class="rounded-lg"
                required
              ></v-text-field>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 3">
              <v-text-field
                v-model="item.apellidoPaterno"
                :counter="50"
                label="Apellido Paterno"
                :rules="rules.textField"
                dense
                outlined
                class="rounded-lg"
                required
              ></v-text-field>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 3">
              <v-text-field
                v-model="item.apellidoMaterno"
                :counter="50"
                label="Apellido Materno"
                dense
                outlined
                class="rounded-lg"
                required
                :rules="rules.textField"
              ></v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 3">
              <v-text-field
                v-model="item.correoVisitante"
                :counter="50"
                label="Correo"
                type="mail"
                dense
                outlined
                class="rounded-lg"
                required
                :rules="rules.emailRules"
              ></v-text-field>
            </v-col>
            <v-divider
              class="mr-10"
              v-if="index < datosVisitantes.length - 1"
            ></v-divider>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="index == 0 && mostrarInputCompania"
                v-model="item.companiaVisitante"
                :counter="50"
                label="Compañia"
                required
                :rules="rules.textField"
              ></v-text-field>
            </v-col>
          </v-row>
          <div
            v-show="visitaGrupal"
            style="display: flex; justify-content: end; align-items: center;"
          >
            <v-btn
              x-small
              fab
              color="teal darken-1"
              class="mx-1"
              dark
              @click="addVisitante"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              x-small
              fab
              color="pink darken-3"
              class="mx-1"
              dark
              @click="removeVisitante()"
            >
              <v-icon> mdi-minus </v-icon>
            </v-btn>
          </div>
        </div>
        <div v-else>
          <div class="center-item-in-div mt-5">
            <v-autocomplete
              v-model="buscaNombreVisitanteProveedor"
              :items="visitantesPermitidosProveedor"
              item-text="Nombre"
              dense
              clareable
              class="rounded-lg"
              style="max-width: 400px;"
              outlined
              placeholder="Busca por nombre..."
              prepend-inner-icon="mdi-magnify"
              v-on:change="addVisitanteProveedor"
            ></v-autocomplete>
          </div>
          <div>
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 3"
                v-for="(item,
                index) in visitantesPermitidosProveedoSeleccionados"
                :key="index"
              >
                <v-card
                  class="px-4 py-2 rounded-lg elevation-0 rounded-lg"
                  width="100%"
                  height="100%"
                  color="blue-grey lighten-5"
                >
                  <v-card-title
                    class="body-2 text-uppercase font-weight-bold blue-grey--text text--darken-3 pa-0"
                  >
                    <v-row dense>
                      <v-col cols="11">{{ item.Nombre }}</v-col>
                      <v-col cols="1" class="text-right"
                        ><v-icon small @click="deleteVisitanteProveedor(item)"
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-card-title>
                  <div class="caption  blue-grey--text text--darken-1">
                    {{ item.Contacto }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <!-- <v-card
              class="rounded-xl px-5 py-3 mb-2 elevation-0"
              color="blue-grey lighten-5"
              :min-width="$vuetify.breakpoint.name === 'xs' ? '300px' : '350px'"
              v-for="(item, index) in visitantesPermitidosProveedoSeleccionados"
              :key="index"
            >
              <v-row dense>
                <v-col cols="11">
                  <div
                    class="body-1 text-uppercase font-weight-bold blue-grey--text text--darken-3"
                  >
                    {{ item.Nombre }}
                  </div>
                  <div class="caption  blue-grey--text text--darken-1">
                    {{ item.Contacto }}
                  </div>
                </v-col>
                <v-col cols="1" class="center-item-in-div">
                  <v-checkbox
                    v-model="item.Selected"
                    class="my-0"
                    color="indigo"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card> -->
          </div>
        </div>
      </v-card>
      <div class="text-center mt-4">
        
      </div>
    </v-form>

    <v-dialog
      v-model="showAlert"
      max-width="520"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl" color="blue-grey lighten-5">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="80"
            ></v-img>
          </div>
          <p
            class="subtitle-1 text-uppercase font-weight-bold text-center mb-3"
          >
            ¡Advertencia!
          </p>
          <p class="subtitle-2 text-uppercase  text-center mb-3">
            {{ mensaje }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            class="rounded-xl font-weight-bold text-decoration-underline"
            depressed
            color="teal darken-1"
            dark
            large
            @click="showAlert = false"
          >
            de acuerdo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showLoadingSetDocuments"
      max-width="820"
      persistent
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl" color="blue-grey lighten-5">
        <v-card-text class="pt-5">
          <v-sheet class="center-item-in-div-column" color="transparent">
            <v-progress-circular
              :size="120"
              :width="12"
              color="purple"
              indeterminate
            ></v-progress-circular>
            <label class="font-weight-bold subtitle-1 text-uppercase">
              Guardando documentos {{ cargados }} /
              {{ accion === 0 ? passes.length : bills.length }}
            </label>
          </v-sheet>
          <v-sheet color="transparent">
            <br />
            <label
              class="overline"
              v-for="(item, index) in responseApiSetDocuments"
              :key="index"
              style="line-height: 0.2;"
            >
              {{ item }} <br />
            </label>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
</v-card-text>
    <v-card-actions class="center-item-in-div">
      <v-btn
          large
          color="teal darken-1"
          dark
          class="rounded-pill"
          depressed
          :loading="loadingSaveVisita"
          @click="validaForm()"
        >
          <v-icon left>mdi-office-building-marker-outline</v-icon>
          Registrar visita
        </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "formulario-registro-de-visitas",
  components: { SnackBar },
  props: ["muestraFormulario"],
  data() {
    return {
      tipoVisitas: [],
      proveedores: [],
      ubicaciones: [],
      colaboradores: [],
      disponibilidad: [],
      bills: [],
      passes: [],
      acciones: [],
      trabajosDeRiesto: [],
      responseApiSetDocuments: [],
      visitantesPermitidosProveedor: [],
      visitantesPermitidosProveedoSeleccionados: [],
      pasesDeSalida: [],
      horariosFijos: [
        "06:00",
        "06:10",
        "06:20",
        "06:30",
        "06:40",
        "06:50",
        "07:00",
        "07:10",
        "07:20",
        "07:30",
        "07:40",
        "07:50",
        "08:00",
        "08:10",
        "08:20",
        "08:30",
        "08:40",
        "08:50",
        "09:00",
        "09:10",
        "09:20",
        "09:30",
        "09:40",
        "09:50",
        "10:00",
        "10:10",
        "10:20",
        "10:30",
        "10:40",
        "10:50",
        "11:00",
        "11:10",
        "11:20",
        "11:30",
        "11:40",
        "11:50",
        "12:00",
        "12:10",
        "12:20",
        "12:30",
        "12:40",
        "12:50",
        "13:00",
        "13:10",
        "13:20",
        "13:30",
        "13:40",
        "13:50",
        "14:00",
        "14:10",
        "14:20",
        "14:30",
        "14:40",
        "14:50",
        "15:00",
        "15:10",
        "15:20",
        "15:30",
        "15:40",
        "15:50",
        "16:00",
        "16:10",
        "16:20",
        "16:30",
        "16:40",
        "16:50",
        "17:00",
        "17:10",
        "17:20",
        "17:30",
        "17:40",
        "17:50",
        "18:00",
        "18:10",
        "18:20",
        "18:30",
        "18:40",
        "18:50",
        "19:00",
        "19:10",
        "19:20",
        "19:30",
        "19:40",
        "19:50",
        "20:00",
        "20:10",
        "20:20",
        "20:30",
        "20:40",
        "20:50",
        "21:00",
        "21:10",
        "21:20",
        "21:30",
        "21:40",
        "21:50",
        "22:00",
        "22:10",
        "22:20",
        "22:30",
        "22:40",
        "22:50",
        "23:00",
        "23:10",
        "23:20",
        "23:30",
        "23:40",
        "23:50",
      ],
      horariosDisponibles: [],
      horariosDeSalida: [
        { valor: 0.5, texto: "Media hora" },
        { valor: 1, texto: "Una hora" },
        { valor: 2, texto: "Dos horas" },
        { valor: 3, texto: "Tres horas" },
        { valor: 4, texto: "Cuatro horas" },
        { valor: 5, texto: "Cinco horas" },
        { valor: 6, texto: "Seis horas" },
        { valor: 7, texto: "Siete horas" },
        { valor: 8, texto: "Ocho horas" },
        { valor: 9, texto: "Nueve horas" },
        { valor: 10, texto: "Diez horas" },
      ],
      proveedorSeleccionado: {},
      privilegios: {},
      datosVisitantes: [
        {
          nombreVisitante: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          correoVisitante: "",
          companiaVisitante: "",
        },
      ],
      frecuencias: [
        {
          id: "dia",
          mensaje: "Día unico",
        },
        {
          id: "semanal",
          mensaje: "Continua",
        },
      ],
      rules: {
        type: [(v) => !!v || "Campo requerido."],
        textField: [
          (v) => !!v || "Campo requerido.",
          (v) =>
            (v && v.length < 100) ||
            "Este campo no puede tener más de 100 caracteres.",
        ],
        select: [(v) => !!v || "Campo requerido."],
        date: [(v) => !!v || "Es necesario que selecciones la fecha."],
        endDate: [
          (v) =>
            (!!v && this.esSemanal) ||
            "Es necesario que selecciones la fecha. " + this.esSemanal,
        ],
        time: [(v) => !!v || "Es necesario que selecciones la hora."],
        emailRules: [
          (v) => !!v || "La dirección e-mail es obligatoria.",
          (v) => /.+@.+\..+/.test(v) || "Ingresa una dirección e-mail valida",
        ],
      },
      colaboradorVisitado: null,
      telefonoColaboradorVisitado: null,
      fileData: null,
      selectedDate: new Date(),
      mostrarInputFechaFinal: false,
      loadingTipoVisita: false,
      loadingBotonGuardar: false,
      modalFechaVisita: false,
      dialogHoraEntrada: false,
      dialogHoraSalida: false,
      modalFechaFinVisita: false,
      mostrarInputCompania: false,
      loadingNombreCompania: false,
      visitaGrupal: false,
      loadingUbicaciones: false,
      loadingColaboradores: true,
      valid: true,
      showAlert: false,
      loadingSaveVisita: false,
      muestraBarra: false,
      otraUbicacion: false,
      disabledEntrada: true,
      loadingEntrada: false,
      realizaraTrabajoDeRiesgo: false,
      loadingAcciones: false,
      loadingTrabajosDeRiesgo: true,
      showLoadingSetDocuments: false,
      disabledRadioUbicaciones: false,
      disabledListaUbicaciones: false,
      esProveedorTemporal: false,
      menuIsActive: false,
      loadingPasesDeSalida: true,
      tienePrivilegios: true,
      muestraMensajeSinPrivilegios: false,
      paseDeSalidaSeleccionado: "",
      buscaNombreVisitanteProveedor: "",
      actionSelected: "",
      textoBarra: "",
      colorBarra: "",
      fechaDeVisita: "",
      horaEntrada: "",
      horaSalida: "",
      fechaFinDeVisita: "",
      nombreCompania: "",
      ubicacionSeleccionada: "",
      frecuencia: "dia",
      tipo: "",
      nPermisoSTPS: "",
      rfc: "",
      correoProveedor: "",
      fechaBloqueo: "",
      motivoVisita: "",
      mensaje: "",
      descAccionProveedor: "",
      otraUbicacionDesc: "",
      accionDeRiesgo: "",
      ubicacionPrincipal: "Planta el salto",
      accion: 0,
      cargados: 0,
    };
  },
  watch: {
    fechaDeVisita(val) {
      this.handleMonthChange(val);
    },
  },
  computed: {
    numColumsInDates() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? 12 : 4;
    },
    esSemanal() {
      return this.frecuencia == "semanal" ? true : false;
    },
    currentMonth() {
      return this.selectedDate.getMonth() + 1;
    },
    currentYear() {
      return this.selectedDate.getFullYear();
    },
  },
  async mounted() {
    const today = new Date();
    this.fechaBloqueo = today.toISOString().substring(0, 10);
    this.horariosDisponibles = this.horariosFijos;
    this.privilegios = await this.getPrivilegios();
    this.tipoVisitas = await this.getTiposVisitantes();
    if (
      this.privilegios.permiso === 0 &&
      this.privilegios.visitasRegistradas >= 1
    ) {
      this.muestraMensajeSinPrivilegios = true;
      const indexAEliminar = this.tipoVisitas.findIndex(
        (objeto) => objeto.id === 4
      );
      if (indexAEliminar !== -1) {
        this.tipoVisitas.splice(indexAEliminar, 1);
      }
      this.tienePrivilegios = false;
    }
    this.colaboradores = await this.getColaboradores();
    this.trabajosDeRiesto = await this.getTrabajosDeRiesgo();
    let findColaborador = this.colaboradores.filter((c) => {
      return c.EMPLID == this.$store.getters.getUser["Folio"];
    })[0];
    this.colaboradorVisitado = findColaborador.nombreMostrar;
    this.telefonoColaboradorVisitado = findColaborador.numTelefono;
  },
  methods: {
    functionEvents(date) {
      let day = this.disponibilidad.filter((d) => {
        return d.fecha == date;
      });
      if (day.length > 0) {
        if (day[0].total >= 300) {
          return "#AD1457";
        } else if (day[0].total < 300 && day[0].total >= 100) {
          return "#FBC02D";
        }
      }
      return "#33691E";
    },
    async handleMonthChange(newDate) {
      let arraydate = newDate.split("-");
      let visibleMonth = parseInt(arraydate[1]);
      let visibleYear = parseInt(arraydate[0]);
      let arrayAux = await this.getFechasDisponibles(visibleMonth, visibleYear);
      this.disponibilidad = [...this.disponibilidad, ...arrayAux];
    },
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    validateEndDate(v) {
      if (this.frecuencia === "semanal" && !v) {
        return "Es necesario que selecciones la fecha.";
      }
      return true;
    },
    updatePhoneNumber() {
      let findColaborador = this.colaboradores.filter((c) => {
        return c.nombreMostrar == this.colaboradorVisitado;
      })[0];
      if (findColaborador !== null && findColaborador !== undefined)
        this.telefonoColaboradorVisitado = findColaborador.numTelefono;
    },
    cambioPorFrecuencia() {
      if (this.frecuencia == "diaunico") {
        this.mostrarInputFechaFinal = true;
      }
      if (this.frecuencia == "semanal") {
        this.mostrarInputFechaFinal = false;
      }
    },
    async getFechasDisponibles(mes, anio) {
      let params = {
        Opcion: 34,
        token: localStorage.getItem("token"),
        mes: mes,
        anio: anio,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingTipoVisita = false;
          });
      });
    },
    deleteHours() {
      const params = {
        Opcion: 35,
        token: localStorage.getItem("token"),
        dia: this.fechaDeVisita,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then((response) => {
          if (response.data.status === "OK") {
            response.data.respuesta.map((horario) => {
              if (
                this.horariosDisponibles.includes(horario.horario) &&
                horario.total >= 5
              ) {
                const indiceAEliminar = this.horariosDisponibles.indexOf(
                  horario.horario
                );
                if (indiceAEliminar !== -1) {
                  this.horariosDisponibles.splice(indiceAEliminar, 1);
                }
              }
            });
          } else if (response.data.status === "EXPRESSION") {
            deleteToken();
          }
        })
        .finally(() => {
          this.disabledEntrada = false;
          this.loadingEntrada = false;
          const fechaActual = new Date();
          const añoActual = fechaActual.getFullYear();
          const mesActual = ("0" + (fechaActual.getMonth() + 1)).slice(-2);
          const diaActual = ("0" + fechaActual.getDate()).slice(-2);
          const fechaHoy = `${añoActual}-${mesActual}-${diaActual}`;
          if (this.fechaDeVisita === fechaHoy) {
            this.validaHorariosMayores();
          }
        });
    },
    validaHorariosMayores() {
      const fechaActual = new Date();
      const añoActual = fechaActual.getFullYear();
      const mesActual = ("0" + (fechaActual.getMonth() + 1)).slice(-2);
      const diaActual = ("0" + fechaActual.getDate()).slice(-2);
      const fechaHoy = `${añoActual}-${mesActual}-${diaActual}`;

      const horariosFiltrados = this.horariosDisponibles.filter((horario) => {
        const horarioFecha = `${fechaHoy} ${horario}`;
        const horarioCompleto = new Date(horarioFecha);

        return horarioCompleto >= fechaActual;
      });
      this.horariosDisponibles = horariosFiltrados;
    },
    async getTiposVisitantes() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.tipos);
            } else if (response.data.status == "EXPSESSION") {
              deleteToken();
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingTipoVisita = false;
          });
      });
    },
    async getPrivilegios() {
      let params = {
        Opcion: 52,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "EXPSESSION") {
              deleteToken();
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          });
      });
    },
    async getTrabajosDeRiesgo() {
      let params = {
        Opcion: 42,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.trabajos);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingTrabajosDeRiesgo = false;
          });
      });
    },
    addVisitante() {
      this.datosVisitantes.push({
        nombreVisitante: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        correoVisitante: "",
        companiaVisitante: "",
      });
    },
    removeVisitante() {
      if (this.datosVisitantes.length === 2) {
        this.datosVisitantes.pop();
        this.visitaGrupal = false;
      } else {
        this.datosVisitantes.pop();
      }
    },
    validaVisitaGrupal() {
      if (this.visitaGrupal && this.datosVisitantes.length == 1) {
        this.addVisitante();
      } else if (!this.visitaGrupal && this.datosVisitantes.length > 1) {
        this.datosVisitantes.map(() => {
          if (this.datosVisitantes.length > 1) {
            this.datosVisitantes.pop();
          }
        });
      }
    },
    getProveedores() {
      if (this.nombreCompania.length >= 3) {
        this.proveedores.length = 0;
        this.loadingNombreCompania = true;
        let params = {
          Opcion: 5,
          token: localStorage.getItem("token"),
          nombre: this.nombreCompania,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.proveedores = response.data.proveedores;
            }
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadingNombreCompania = false;
          });
      } else {
        if (this.menuIsActive) {
          this.proveedores.length = 0;
        }
      }
    },
    async selectItem(item) {
      this.proveedorSeleccionado = item;
      this.nombreCompania = item.NOMBRE;
      this.nPermisoSTPS = item.NO_PROVEEDOR;
      this.rfc = item.RFC;
      this.correoProveedor = item.CORREO;
      this.proveedores = [];
      this.menuIsActive = false;
      this.esProveedorTemporal = [null, "", undefined].includes(
        item.NO_PROVEEDOR
      )
        ? true
        : false;
      // this.buscaProveedorTemporal();
      this.visitantesPermitidosProveedor = await this.getVisitantesPermitidosProveedores().catch(
        (e) => {
          alert(e);
        }
      );
    },
    cahngeUbicacionPrincipal() {
      console.log(this.ubicacionPrincipal,['CD Sur','Américas'].includes(this.ubicacionPrincipal))
      if (this.tipo != "Proveedor") {
        let UbicacionBuscada = this.ubicaciones.filter((u) => {
          console.log(u.Ubicacion + " == " + this.ubicacionPrincipal)
          return u.Ubicacion == this.ubicacionPrincipal;
        });
        this.ubicacionSeleccionada = UbicacionBuscada[0].Ubicacion;
      }  else if (this.tipo == "Proveedor" && ['CD Sur','Américas'].includes(this.ubicacionPrincipal)) {
        this.ubicacionSeleccionada = this.ubicacionPrincipal;
      }
    },
    async buscaProveedorTemporal() {
      let validaProveedor = await this.findVisitaDeProveedorTemporal();
      if (this.nPermisoSTPS === "" || validaProveedor) {
        this.esProveedorTemporal = true;
        this.accion = 4;
        this.actionSelected = "Cotización";
      }
    },
    async getUbicacion() {
      let idTipo = this.tipoVisitas.filter((t) => {
        return t.descr == this.tipo;
      })[0];
      this.acciones = await this.getAcciones(idTipo.id);
      const params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        tipo: idTipo.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "OK") {
            this.ubicaciones = response.data.ubicaciones;
            if (this.tipo != "Proveedor") {
              let UbicacionBuscada = this.ubicaciones.filter((u) => {
                return u.Ubicacion == this.ubicacionPrincipal;
              });
              this.ubicacionSeleccionada = UbicacionBuscada[0].Ubicacion;
              this.disabledListaUbicaciones = true;
            } else {
              this.ubicacionPrincipal = "Planta el salto";
              this.pasesDeSalida = await this.getPasesDeSalidaAutorizado();
              this.disabledListaUbicaciones = false;
              this.disabledRadioUbicaciones = true;
            }
          }
        })
        .catch((error) => {
          alert([{ nombre: error }]);
        })
        .finally(() => {
          this.loadingUbicaciones = false;
        });
    },
    async getAcciones(idTipo) {
      const params = {
        Opcion: 41,
        token: localStorage.getItem("token"),
        idTipo: idTipo,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingAcciones = false;
          });
      });
    },
    async getColaboradores() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.colaboradores);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingColaboradores = false;
          });
      });
    },
    getPasesDeSalidaAutorizado() {
      let params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingPasesDeSalida = false;
          });
      });
    },
    async validaForm() {
      if (this.$refs.formVisits.validate()) {
        let validaProveedor = await this.findVisitaDeProveedorTemporal();
        let esValido = false;
        if (
          this.tipo == "Proveedor" &&
          this.rfc == "" &&
          this.frecuencia != "semanal" &&
          !validaProveedor
        ) {
          this.mensaje =
            "Estás por registrar un proveedor temporal, que solo tendrá acceso el día de la visita y una vez en los próximos 30 días.";
          this.showAlert = true;
          esValido = true;
        } else if (
          this.tipo == "Proveedor" &&
          this.rfc == "" &&
          this.frecuencia == "semanal"
        ) {
          this.mensaje =
            "Las visitas de provedores temporales no pueden ser por más de un día.";
          this.showAlert = true;
        } else if (
          this.tipo == "Proveedor" &&
          this.rfc == "" &&
          this.frecuencia != "semanal" &&
          validaProveedor
        ) {
          this.mensaje =
            "El proveedor registrado realizó o realizará una visita en un lapso menor a 30 días a su última visita.";
          this.showAlert = true;
        } else if (
          this.datosVisitantes.length === 0 &&
          this.visitantesPermitidosProveedor === 0
        ) {
          esValido = false;
        } else {
          esValido = true;
        }
        if (
          this.tipo == "Proveedor" &&
          this.accion == 1 &&
          this.bills.length == 0
        ) {
          esValido = false;
          this.muestraSnackBar(
            "pink darken-3",
            "Es necesario que cargues la evidencia de las facturas."
          );
        }

        if (
          this.tipo == "Proveedor" &&
          this.accion == 0 &&
          this.paseDeSalidaSeleccionado == ""
        ) {
          esValido = false;
          this.muestraSnackBar(
            "pink darken-3",
            "Es necesario que ligues tu registro a un pase de salida autorizado."
          );
        }
        if (esValido) {
          this.loadingSaveVisita = true;
          let findColaborador = this.colaboradores.filter((c) => {
            return c.nombreMostrar == this.colaboradorVisitado;
          })[0];
          let findTipo = this.tipoVisitas.filter((t) => {
            return t.descr == this.tipo;
          })[0];
          let valorHoraSalida = this.horariosDeSalida.filter((s) => {
            return s.texto == this.horaSalida;
          })[0];
          let itemAccionRealizar = this.acciones.filter((a) => {
            return a.descr == this.actionSelected;
          })[0];
          let itemTrabajoRiesgo = this.trabajosDeRiesto.filter((t) => {
            return t.descripcion == this.accionDeRiesgo;
          });

          let infoPase = this.pasesDeSalida.filter((passe) => {
            return passe.nombreMostrar == this.paseDeSalidaSeleccionado;
          });

          let visitantes = [];
          if (this.datosVisitantes.length > 0 && this.tipo != "Proveedor") {
            visitantes = this.datosVisitantes;
          } else if (
            this.datosVisitantes.length > 0 &&
            this.visitantesPermitidosProveedoSeleccionados.length === 0 &&
            this.tipo == "Proveedor"
          ) {
            visitantes = this.datosVisitantes;
          } else {
            this.visitantesPermitidosProveedoSeleccionados.map((v) => {
              visitantes.push({
                nombreVisitante: v.Nombre,
                apellidoPaterno: "",
                apellidoMaterno: "",
                correoVisitante: this.correoProveedor,
                companiaVisitante: this.companiaVisitante,
              });
            });
          }

          if (itemTrabajoRiesgo.length == 0) {
            itemTrabajoRiesgo = null;
          }
          const params = {
            Opcion: 3,
            token: localStorage.getItem("token"),
            tipo: findTipo,
            razonSocial: this.nombreCompania,
            permisoSTPS: this.nPermisoSTPS,
            rfc: this.rfc,
            correoProveedor: this.correoProveedor,
            frecuencia: this.frecuencia,
            fechaDeVisita: this.fechaDeVisita,
            fechaFinDeVisita: this.fechaFinDeVisita,
            horaEntrada: this.horaEntrada,
            horaSalida: valorHoraSalida.valor,
            colaboradorVisitado: findColaborador,
            telefonoColaboradorVisitado: this.telefonoColaboradorVisitado,
            ubicacionSeleccionada: this.ubicacionSeleccionada,
            datosVisitantes: visitantes, //this.tipo === "Proveedor" != this.datosVisitantes.length === 0 ? this.datosVisitantes : this.visitantesPermitidosProveedor,
            otraUbicacion: this.otraUbicacion,
            otraUbicacionDesc: this.otraUbicacionDesc,
            accion: this.accion,
            descAccionProveedor: this.descAccionProveedor,
            actionSelected:
              itemAccionRealizar === undefined
                ? { id: 44, descr: "Cotización" }
                : itemAccionRealizar,
            realizaraTrabajoDeRiesgo: this.realizaraTrabajoDeRiesgo,
            accionDeRiesgo: itemTrabajoRiesgo,
            paseDeSalida: infoPase[0] ? infoPase[0].id : 0,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
              params
            )
            .then(async (response) => {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  if (
                    this.tipo != "Proveedor" ||
                    (this.tipo == "Proveedor" && this.accion != 1)
                  ) {
                    // this.muestraSnackBar(
                    //   "teal darken-1",
                    //   "Visita registrada con éxito."
                    // );
                    this.$emit('cierraForm', true)
                  } else if (this.tipo == "Proveedor" && this.accion == 1) {
                    this.showLoadingSetDocuments = true;
                    const index = this.bills.length;

                    const promises = [];

                    for (let i = 0; i < index; i++) {
                      const filename = this.bills[i].name;
                      const file = this.bills[i].data;

                      const promise = this.setDocumentProvider(
                        filename,
                        response.data.respuesta.idVisita,
                        file
                      )
                        .then((responseApi) => {
                          if (responseApi.status_tran === "OK") {
                            this.cargados++;
                          }
                          this.responseApiSetDocuments.push(
                            responseApi.message
                          );
                        })
                        .catch((e) => {
                          this.cargados++;
                          this.responseApiSetDocuments.push(
                            e + " fallo la carga de " + filename
                          );
                        });

                      promises.push(promise);
                    }

                    await Promise.all(promises);
                    this.this.$emit('cierraForm', true);
                    this.loadingSaveVisita = false;

                    this.muestraSnackBar(
                      "teal darken-1",
                      response.data.respuesta.message
                    );
                  }
                } else {
                  this.muestraSnackBar(
                    "pink darken-3",
                    response.data.respuesta.message
                  );
                }
              }
            })
            .catch((error) => {
              this.muestraSnackBar("teal darken-1", error);
            })
            .finally(() => {
              if (this.cargados == this.responseApiSetDocuments.length) {
                this.bills.length = 0;
                this.passes.length = 0;
                this.datosVisitantes = [
                  {
                    nombreVisitante: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    correoVisitante: "",
                    companiaVisitante: "",
                  },
                ];
                this.visitantesPermitidosProveedor.length = 0;
                this.datosVisitantes.length = 0;
                this.paseDeSalidaSeleccionado = "";
                this.buscaNombreVisitanteProveedor = "";
                this.actionSelected = "";
                this.fechaDeVisita = "";
                this.horaEntrada = "";
                this.horaSalida = "";
                this.fechaFinDeVisita = "";
                this.nombreCompania = "";
                this.ubicacionSeleccionada = "";
                this.frecuencia = "dia";
                this.tipo = "";
                this.nPermisoSTPS = "";
                this.rfc = "";
                this.correoProveedor = "";
                this.fechaBloqueo = "";
                this.motivoVisita = "";
                this.mensaje = "";
                this.descAccionProveedor = "";
                this.otraUbicacionDesc = "";
                this.accionDeRiesgo = "";
                this.ubicacionPrincipal = "Planta el salto";
                this.accio = 0;
                this.cargado = 0;
                this.loadingSaveVisita = false;

                this.showLoadingSetDocuments = false;
                this.$refs.formVisits.resetValidation();
              }
            });
        }
      } else {
        this.muestraSnackBar(
          "pink darken-3",
          "Hay campos obligatorios sin llenar"
        );
      }
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    async findVisitaDeProveedorTemporal() {
      const params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        razonSocial: this.nombreCompania,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK" && response.data.respuesta > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    obtenerHorariosDeSalida() {
      // const horaDeEntrada = parseInt(this.horaEntrada.substring(0, 2));
      // const horariosFiltrados = this.horariosDeSalida.filter(
      //   (horario) => horaDeEntrada + horario.valor <= 22
      // );
      const horariosFiltrados = this.horariosDeSalida;
      this.horariosDeSalida = horariosFiltrados;
    },
    getFechaMaxima() {
      if (this.fechaDeVisita != "") {
        let fechaMaxima = new Date(this.fechaDeVisita);
        fechaMaxima.setDate(fechaMaxima.getDate() + 29);
        return fechaMaxima.toISOString().substr(0, 10);
      } else {
        let fechaMaxima = new Date();
        fechaMaxima.setDate(fechaMaxima.getDate() + 29);
        return fechaMaxima.toISOString().substr(0, 10);
      }
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async addDocument() {
      if (![null, undefined, ""].includes(this.fileData)) {
        const fileName = this.fileData.name;
        const doc = await this.readFileAsBase64(this.fileData);
        const arrayFileData = doc.split(";");
        if (this.accion === 1) {
          if (arrayFileData[0].includes("pdf")) {
            this.bills.push({
              type: "pdf",
              data: doc,
              name: fileName,
            });
          } else {
            this.bills.push({
              type: "image",
              data: doc,
              name: fileName,
            });
          }
        } else {
          if (arrayFileData[0].includes("pdf")) {
            this.passes.push({
              type: "pdf",
              data: doc,
              name: fileName,
            });
          } else {
            this.passes.push({
              type: "image",
              data: doc,
              name: fileName,
            });
          }
        }
        this.fileData = null;
      }
    },
    async setDocumentProvider(filename, idVisita, file) {
      let params = {
        Opcion: 43,
        token: localStorage.getItem("token"),
        filename: filename,
        idVisita: idVisita,
        file: file,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async test() {
      this.showLoadingSetDocuments = true;
      const index = this.accion === 0 ? this.passes.length : this.bills.length;

      for (let i = 0; i < index; i++) {
        const filename =
          this.accion === 0 ? this.passes[i].name : this.bills[i].name;
        const file =
          this.accion === 0 ? this.passes[i].data : this.bills[i].data;
        const response = await this.setDocumentProvider(filename, 3030, file);
        if (response) {
          if (response.status_tran === "OK") {
            this.cargados++;
          }
          this.responseApiSetDocuments.push(response.message);
        }
      }
    },

    async getVisitantesProveedores() {
      if (this.tipo === "Proveedor") {
        this.visitantesPermitidosProveedor = await this.getVisitantesPermitidosProveedores();
      }
    },
    getVisitantesPermitidosProveedores() {
      let params = {
        Opcion: 44,
        token: localStorage.getItem("token"),
        proveedor: this.nPermisoSTPS,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          });
        // .finally(() => {
        //   this.loadingTipoVisita = false;
        // });
      });
    },

    addVisitanteProveedor() {
      let findProveedor = this.visitantesPermitidosProveedor.filter((p) => {
        return p.Nombre === this.buscaNombreVisitanteProveedor;
      });

      if (findProveedor.length > 0) {
        this.visitantesPermitidosProveedoSeleccionados.push(findProveedor[0]);
        this.buscaNombreVisitanteProveedor = "";
      }
    },
    deleteVisitanteProveedor(objetoAEliminar) {
      // Utiliza la función filter para crear un nuevo array sin el objeto a eliminar
      var nuevoArray = this.visitantesPermitidosProveedoSeleccionados.filter(
        function(objeto) {
          return objeto !== objetoAEliminar;
        }
      );

      // Devuelve el nuevo array sin el objeto
      this.visitantesPermitidosProveedoSeleccionados = nuevoArray;
    },
  },
};
</script>
