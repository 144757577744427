<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="cuadroBase"
      :search="buscar"
      :loading="loadingInfo"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      loading-text="Cargando cuadro base"
      class="elevation-1"
      dense
      mobile-breakpoint="0"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      no-data-text="No se encontraron registros"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
          <v-text-field
            v-model="buscar"
            :label="$t('DH360.findTextBox')"
            class="mt-7 rounded-lg"
            dense
            clearable
            outlined
            solo-inverted
            prepend-inner-icon="mdi-table-search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-2"
            dark
            small
            class="mb-1 font-weight-bold rounded-lg"
            @click="abrirFormulario('Nuevo')"
          >
            <v-icon left small>mdi-plus</v-icon>
            Nuevo
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div
            align="center"
            class="overline mx-auto pa-5"
            v-if="!item.puesto && !loadingInfo"
          >
            <v-icon>mdi-alert</v-icon> No se han asignado puestos al cuadro base
            <br />
            Departamento: <b> {{ item.DESCR }} [{{ item.depto }}] </b> <br />
            Turno: <b> {{ item.Descripcion }}</b>
          </div>
          <v-row
            class="d-flex justify-space-around mb-6 pa-8"
            v-else-if="item.puesto && !loadingInfo"
          >
            <v-col
              class="py-0 overline d-flex justify-center"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              >Lista de puestos: {{ item.puesto.length }}</v-col
            >
            <v-divider></v-divider>
            <v-col
              v-for="(i, index) in item.puesto"
              :key="index"
              class="py-0"
              cols="12"
              xl="3"
              lg="4"
              md="4"
              sm="6"
              xs="12"
            >
              <v-card elevation="2" class="rounded-lg mb-5">
                <v-card-title class="text-subtitle-2 primary white--text">
                  [{{ i.JOBCODE }}] {{ i.DESCR }}
                </v-card-title>
                <div class="pa-5 overline d-flex justify-center">
                  Numero de puestos: <b>{{ i.numPuestos }}</b>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.depto="{ item }">
        <div align="left">{{ item.DESCR }} [{{ item.depto }}]</div>
      </template>
      <template v-slot:item.Descripcion="{ item }">
        <div align="left">{{ item.Descripcion }}</div>
      </template>
      <template v-slot:item.tipoOp="{ item }">
        <div align="left">{{ item.tipoOp }}</div>
      </template>
      <template v-slot:item.plantaNombre="{ item }">
        <div align="left">[{{ item.planta }}] {{ item.plantaNombre }}</div>
      </template>

      <template v-slot:item.estatus="{ item }">
        <v-tooltip left color="blue lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue darken-4"
              v-bind="attrs"
              v-on="on"
              @click="abrirFormulario(item)"
            >
              mdi-pencil-box-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Editar</label
          >
        </v-tooltip>
        <v-tooltip top color="pink lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="pink darken-4"
              v-bind="attrs"
              v-on="on"
              @click="openDialogRemove(item)"
            >
              mdi-delete-empty-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold pink--text"
            >Remover</label
          >
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    /> -->
    <form-cuadro-base
      v-if="dialogForm"
      :dialog="dialogForm"
      :item="item"
      @cierraForm="cerrarFormulario"
    ></form-cuadro-base>
    <delete-cuadro-base
      v-if="dialogRemove"
      :dialog="dialogRemove"
      :item="item"
      @cierraDialogDelete="cerrarDialog"
    ></delete-cuadro-base>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import FormCuadroBase from "./FormCuadroBase.vue";
import DeleteCuadroBase from "./DeleteCuadroBase.vue";
// import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "lista-cuadro-base",
  components: { FormCuadroBase, DeleteCuadroBase },
  props: [],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    headers: [
      {
        text: "Departamento",
        value: "depto",
        align: "center",
        sortable: true,
      },
      { text: "Turno", value: "Descripcion", align: "center", sortable: true },
      { text: "Plan", value: "plandept", align: "center", sortable: true },
      { text: "Tipo", value: "tipoOp", align: "center", sortable: true },
      {
        text: "Planta",
        value: "plantaNombre",
        align: "center",
        sortable: true,
      },
      { text: "Acciones", value: "estatus", align: "center", sortable: true },
    ],
    puestos: [],
    cuadroBase: [],
    buscar: "",
    loadingInfo: true,
    dialogForm: false,
    item: "",
    expanded: [],
    singleExpand: true,
    dialogRemove: false,
  }),
  created() {},
  computed: {},
  mounted() {
    this.getCuadroBase();
  },

  methods: {
    getCuadroBase() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-cuadro-base`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.cuadroBase = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },

    abrirFormulario(item) {
      this.item = item;
      this.dialogForm = true;
    },

    cerrarFormulario(response) {
      this.item = "";
      this.dialogForm = false;
      if (response == "OK") {
        this.cuadroBase = [];
        this.loadingInfo = true;
        this.getCuadroBase();
      }
    },

    openDialogRemove(item) {
      this.dialogRemove = true;
      this.item = item;
    },

    cerrarDialog(response) {
      this.item = "";
      this.dialogRemove = false;
      if (response == "OK") {
        this.cuadroBase = [];
        this.loadingInfo = true;
        this.getCuadroBase();
      }
    },
  },
};
</script>