import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
// import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import moment from "moment/moment";
import Bar from './components/BarBarrasTE.vue';
//import PersonaMotivoTE from "../../../General/Inicio/components/PersonaMotivoTE.vue";
export default {
  name: 'reporte-costo-de-tiempo-extra',
  components: {
    Bar,
    //PersonaMotivoTE,
  },
  props: [],
  data() {
    return {
      loading: false,
      numsemana: 0,
      semanareporte: 0,
      selectsemana: null,
      numanio: null,
      inicionom: null,
      finnom: null,
      costoreporte: null,
      reportecosto: [],
      semana: [],
      panel: [0, 1],


      headerCostoSolis: [

        { text: 'EMPLEADO', value: 'numemplid', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'NOMBRE', value: 'nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'AREA', value: 'areaname', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'TURNO', value: 'turno', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'SUELDO DIARIO', value: 'sueldodiario', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'HRS.DOBLES', value: 'horasdobles', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'HRS.TRIPLES', value: 'horastriples', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'TOTAL DE HORAS', value: 'totalhoras', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: '$ DOBLES', value: 'costodobles', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: '$ TRIPLES', value: 'costotriples', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'TOTAL $ PAGADO', value: 'costototal', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],
    }
  },
  computed: {

  },
  mounted() {
    this.getdatosfecha();
  },
  methods: {
    //Obtiene los motivos de la solicitud de TE.
    getReporteCosto() {
      this.loading = true;
      let semanaCadena = this.selectsemana < 10 ? '0' + this.selectsemana.toString() : this.selectsemana.toString();
      let params = {
        Opcion: 9,
        año: this.numanio,
        semana: semanaCadena,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
        if (response.data.status.trim() == "EXPSESSION") {
          deleteToken();
        } else {
          var data = response.data.costote.costotiempoextra;
          this.costoreporte = response.data.costote.costototalconcepto;
          this.costoreporte = this.costoreporte.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          });
          console.log(data);
          if (data) {
            this.reportecosto = data;

          }
        }
        this.loading = false;
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getdatosfecha() {
      // Obtiene el número de la semana actual.
      const weekNumber = moment().week();
      this.numsemana = weekNumber;
      this.selectsemana = weekNumber;
      
      // Obtiene el año actual.
      const yearactual = moment().year();
      this.numanio = yearactual;
      
      // Crea un arreglo con las semanas hasta una semana anterior de manera descendente.
      const weeks = [];
      for (let i = weekNumber - 1; i >= 1; i--) {
        weeks.push(i);
      }
      
      this.semana = weeks;
      console.log(weeks);
    },
    getSemanaActual(anio, semana) {
      this.getReporteCosto();

      var startOfWeek = moment().year(anio).week(semana).startOf('week').add(1, 'days');
      var endOfWeek = moment().year(anio).week(semana).endOf('week').add(2, 'days');
      startOfWeek.locale('es');
      endOfWeek.locale('es');

      startOfWeek.format('dd MM yyyy');
      endOfWeek.format('dd MM yyyy');



      var inicioDate = startOfWeek.format('dddd, D MMMM yyyy');
      var finalDate = endOfWeek.format('dddd, D MMMM yyyy');

      this.inicionom = inicioDate;
      this.finnom = finalDate;

      console.log(inicioDate);// 'lunes, 07 marzo 2023'
      console.log(finalDate);

    },
  }
}


