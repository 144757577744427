import axios from "axios";
import moment from "moment/moment";
import VueJsonToCsv from "vue-json-to-csv";
import { deleteToken } from "../../../../../store/Funciones/funciones";

export default {
  name: 'reporte-general-tiempo-extra',
  components: { VueJsonToCsv },
  props: [],
  data() {
    return {
      ADMING: 1,
      pickersolicitudesgen: false,
      searchsolis: '',
      datesolisgen: [],
      infosolisgen: [],
      expandedgeneral: [],
      loading: false,
      numsemana: 0,

      headersoliscitudesgen: [

        { text: '#SOLICITUD', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'JUSTIFICACION', value: 'JUSTIFICACION', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'AREA', value: 'AREA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FECHA DE REGISTRO', value: 'FECHA_CREACION', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'HORA DE REGISTRO', value: 'HORA_CREACION', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'USUARIO CREACION', value: 'USUARIO_CREACION', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ESTADO DE LA SOLICITUD', value: 'ESTATUS', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],

      // Apartado descarga de motivos por mes.
      dExportMotivos: false,
      reporteJust: [],
      meses: [],
      numAnio: "",
      mes: null,
      loadingDate: false,


      nameFile: "1",

      headers_report_to_export: {
        //SOLICITUD: { title: "ID SOLICITUD" },
        //HORAS_TOTALES: { title: "HORAS TOTALES" },
        USUARIO_ACTUALIZA: { title: "N° EMPLEADO REGISTRO" },
        Nombre: { title: "NOMBRE" },
        //AREA: { title: "ID AREA" },
        AREADESC: { title: "DESCRIPCION DE AREA DE REGISTRO" },
        //TIPO_JUSTIFICACION: { title: "ID JUSTIFICACION" },
        DESCJUSTIFICACION: { title: "DESCRIPCION DE LA JUSTIFICACION" },
        FIG_MAX: { title: "FIGURAS MAXIMAS" },
        FIG_MIN: { title: "FIGURAS MINIMAS" },
        MONTO: { title: "MONTO" },
        //AREA_MANTENIMIENTO: { title: "ID AREA DE MANTENIMIENTO" },
        DESCMANTAREA: { title: "DECRIPCION AREA DE MANTENIMIENTO" },
        COMENTARIO: { title: "COMENTARIO" },
        //IDGRUPO: { title: "IDGRUPO" },
        ID_EMPLEADO: { title: "N° EMPLEADO GRUPO" },
        NAME: { title: "NOMBRE" },
        FECHA: { title: "FECHA TE" },
        HORAS: { title: "HORAS" },
        HORAS_REALES: { title: "HORAS REALES" },
        FECHA_ACTUALIZA: { title: "FECHA DE REGISTRO" },
      },

      //Reporte pago te semanal
      reportepagote: [],
      nameFileRPTE: "1",
      respuestadia: 0,

      headers_reportpagote_to_export: {
        IDEMPLEADO: { title: "N° EMPLEADO" },
        NOMBRE: { title: "NOMBRE" },
        GP_PAYGROUP: { title: "GRUPO DE PAGO" },
        FECHA: { title: "FECHA" },
        HORAS_SOLICITADAS: { title: "HORAS SOLICITADAS" },
        HORAS_TRABAJADAS: { title: "HORAS TRABAJADAS" },
        INGRESO: { title: "ENTRADA" },
        SALIDA: { title: "SALIDA" },
        ESTADO: { title: "ESTADO" },
        FECHA_MODIFICA: { title: "FECHA DE REGISTRO" },
      },

      //pantalla cumplimiento motivo te.
      planproduccion: [],
      pedidoextraordinario: [],
      mantenimiento: [],
      impactoec: [],
      tabs: null,
      planproduccionporcentaje:"",
      pedidoextraordinarioporcentaje:"",
      mantenimientoporcentaje:"",
      impactoecporcentaje:"",
      planproduccioncolor:"",
      pedidoextraordinariocolor:"",
      mantenimientocolor:"",
      impactoeccolor:"",
      dactcumplimiento:false,
      idregistro:0,
      cumplimientoupdt:"",
      
      headerplanproduccion: [
        { text: 'ID', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'SOLICITUD', value: 'ID_SOLICITUD', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FIGURAS TOTALES', value: 'FIG_TOTALES', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'OBJETIVO', value: 'OBJETIVO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'EMPLID', value: 'USUARIO_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'NOMBRE', value: 'Nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FECHA REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],
      headerpedidoextraordinario: [
        { text: 'ID', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'SOLICITUD', value: 'ID_SOLICITUD', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'MONTO TOTAL', value: 'MONTO_TOTAL', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'OBJETIVO', value: 'OBJETIVO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'EMPLID', value: 'USUARIO_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'NOMBRE', value: 'Nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FECHA REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],
      headermantenimiento: [
        { text: 'ID', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'SOLICITUD', value: 'ID_SOLICITUD', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'COMENTARIO MANTENIMIENTO', value: 'COM_MANT', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'OBJETIVO', value: 'OBJETIVO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'EMPLID', value: 'USUARIO_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'NOMBRE', value: 'Nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FECHA REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ACCIONES', value: 'actions', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],
      headerimpactoec: [
        { text: 'ID', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'SOLICITUD', value: 'ID_SOLICITUD', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'COMENTARIO IMPACTO EN EQUIPO CAIDO', value: 'COM_IMPEC', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'OBJETIVO', value: 'OBJETIVO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'EMPLID', value: 'USUARIO_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'NOMBRE', value: 'Nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FECHA REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ACCIONES', value: 'actions2', align: 'center', class: 'primary white--text overline font-weight-bold' },

      ],

    }
  },
  computed: {

  },
  mounted() {
    this.getdatosfecha();
    this.lunesMuestraExport();
  },
  methods: {
    getSolicitudes() {

      this.infosolisgen = [];
      this.loading = true;

      // Obtener la fecha de hace 7 días
      var fechaHace7Dias = moment().subtract(7, 'days');

      // Formatear la fecha en formato día-mes-año numérico
      var fechaFormateada = fechaHace7Dias.format('YYYY-MM-DD');
      var fechaActual = moment().format('YYYY-MM-DD');
      var inicial1 = 0;
      var final2 = 0;
      inicial1 = fechaFormateada += "T00:00:00.000";
      final2 = fechaActual += "T23:59:59.00";

      let params = {
        Opcion: 5,
        fechainicial: inicial1,
        fechafinal: final2,
        pantalla: 2,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
        var data = response.data.infosolis;
        if (data) {

          this.infosolisgen = data;
          this.loading = false;

        } else {
          this.loading = false;
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getColor(ESTATUSSTE) {
      if (ESTATUSSTE == "En proceso de validación") return '#006064'
      if (ESTATUSSTE == "Rechazado") return '#EF5350' //RED LIGHTEN -1
      if (ESTATUSSTE == "Autorizado") return '#66BB6A' //GREEN LIGHTEN -1
      if (ESTATUSSTE == "Enviado a proceso de pago") return '#FFCA28' // AMBER LIGHTEN -1
      if (ESTATUSSTE == "Rechazado el proceso de pago") return '#FFA726' //OREANGE LIGHTEN -1
      if (ESTATUSSTE == "finalizado") return '#29B6F6' //LIGHT-BLUE-LIGHTEN -1
      if (ESTATUSSTE == "Terminado") return '#29B6F6' //LIGHT-BLUE-LIGHTEN -1 
    },
    getdatosfecha() {
      //obtiene # la semana actual.
      const weekNumber = moment().week();
      this.numsemana = weekNumber;

      this.getSolicitudes();
    },
    fixName() {

      this.nameFile = "ReporteJustificacionTE" + this.numAnio + this.mes;
    },

    // Función para convertir el nombre del mes en número
    convertirMesANumero(nombreMes) {
      let nummes = "";
      switch (nombreMes) {
        case "enero":
          nummes = "01";
          break;
        case "febrero":
          nummes = "02";
          break;
        case "marzo":
          nummes = "03";
          break;
        case "abril":
          nummes = "04";
          break;
        case "mayo":
          nummes = "05";
          break;
        case "junio":
          nummes = "06";
          break;
        case "julio":
          nummes = "07";
          break;
        case "agosto":
          nummes = "08";
          break;
        case "septiembre":
          nummes = "09";
          break;
        case "octubre":
          nummes = "10";
          break;
        case "noviembre":
          nummes = "11";
          break;
        case "diciembre":
          nummes = "12";
          break;
        default:
          nummes = "0";
      }
      return nummes;

    },
    obtenerUltimoDiaMes(mes, anio) {
      // Crear un objeto Moment con el primer día del mes especificado
      const fecha = moment({ year: anio, month: parseInt(mes) - 1 });

      // Obtener el último día del mes
      const ultimoDia = fecha.endOf('month').date();

      return ultimoDia;
    },

    getReporte() {
      this.loadingDate = true;
      const numeroMes = this.convertirMesANumero(this.mes);
      const ultimoDia = this.obtenerUltimoDiaMes(numeroMes, this.numAnio);
      // armar la fecha 
      var fechaini = this.numAnio + "-" + numeroMes + "-" + "01" + "T00:00:00.000";
      var fechafin = this.numAnio + "-" + numeroMes + "-" + ultimoDia + "T23:59:59.00";

      let params = {
        Opcion: 13,
        fechainicio: fechaini,
        fechafinal: fechafin,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
        if (response.data.status.trim() == "EXPSESSION") {
          deleteToken();
        } else {
          var data = response.data.justreport;
          if (data) {
            this.reporteJust = data;
            this.loadingDate = false;
          }
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    getdatosFechaReporte() {
      this.dExportMotivos = true;
      // Obtener el mes actual
      const mesActual = moment().month();
      // this.numMes = mesActual + 1; // Sumamos 1 porque los meses en Moment.js van de 0 a 11
      // this.selectMes = mesActual + 1;

      // Obtener el año actual
      const anioActual = moment().year();
      this.numAnio = anioActual;

      // Crear un arreglo con los nombres de los meses hasta el mes actual del año
      const meses = [];
      for (let i = 0; i < mesActual; i++) {
        const nombreMes = moment().month(i).locale('es').format('MMMM');
        meses.push(nombreMes);
      }
      this.meses = meses;
      console.log(meses);
    },

    getReporteTEPago() {
      let params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
        if (response.data.status.trim() == "EXPSESSION") {
          deleteToken();
        } else {
          var data = response.data.Rpago;
          if (data) {
            this.reportepagote = data;
          }
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    lunesMuestraExport() {
      const ahora = moment();
      //comprobar si es lunes.
      if (ahora.day() == 1) {
        //comprobar si es despues de las 12:10.
        const horalimite = moment('12:10 PM', 'h:mm A');
        if (ahora.isAfter(horalimite)) {
          //Muestra el botón 
          this.respuestadia = 1;
          this.getReporteTEPago();

        } else {
          //No mostrar el botón para exportar el reporte.
          this.respuestadia = 0;
        }
      } else {
        //No mostrar el botón para exportar el reporte.
        this.respuestadia = 0;
      }
    },
    fixNamerpago() {

      const diaActual = moment().date();
      this.nameFileRPTE = "Reporte Pago de Tiempo Extra del Lunes " + diaActual;
    },
    getReporteCumplimiento() {
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
                        
            if(response.data.plan.plan){
              this.planproduccion = response.data.plan.plan;
            }
            
            if(response.data.pedido.pedido){
              this.pedidoextraordinario = response.data.pedido.pedido;
            }
            
            if(response.data.mantenimiento.mantenimiento){
              this.mantenimiento = response.data.mantenimiento.mantenimiento;
            }
            
            if(response.data.impacto.impec){
              this.impactoec = response.data.impacto.impec;
            }
            // Calcular el porcentaje de cumplimiento
            if(this.planproduccion != []){
              this.planproduccionporcentaje = this.calcularPorcentajeCumplimiento(this.planproduccion);
            }
            if(this.pedidoextraordinario != []){
              this.pedidoextraordinarioporcentaje = this.calcularPorcentajeCumplimiento(this.pedidoextraordinario);
            //this.pedidoextraordinariocolor = this.getColorporcentaje(this.pedidoextraordinarioporcentaje);
            }
            if(this.mantenimiento){
              this.mantenimientoporcentaje = this.calcularPorcentajeCumplimiento(this.mantenimiento);
            }
            if(this.impactoec != []){
              this.impactoecporcentaje = this.calcularPorcentajeCumplimiento(this.impactoec);
            }
          }
        });
    },

    calcularPorcentajeCumplimiento(datos) {
      let totalCumplidos = 0;

      // Recorrer el arreglo y contar cuántos elementos cumplen la condición en la posición objetivo
      datos.forEach((elemento) => {
        if (elemento.OBJETIVO === "Cumplido") {
          totalCumplidos++;
        }
      });

      // Calcular el porcentaje de cumplimiento
      const porcentajeCumplimiento = (totalCumplidos / datos.length) * 100;

      return porcentajeCumplimiento.toFixed(2); // Limitar a dos decimales
    },
    getColorProgress(porcentaje) {
      if (parseFloat(porcentaje) < 30 || porcentaje =="NaN") {
        return 'red darken-2'; // Color rojo para porcentajes menores al 30%
      } else if (parseFloat(porcentaje) < 70) {
        return 'amber'; // Color amarillo para porcentajes entre 30% y 70%
      } else {
        return 'teal'; // Color verde para porcentajes iguales o mayores al 70%
      }
    },
    actcumplimiento(item){
      this.dactcumplimiento = true;
      console.log(item.OBJETIVO +" "+ item.ID);
      this.idregistro = item.ID;
       
    },
    updtcumplimiento(valor){
      if(valor == 1){
        //cumplido
        this.cumplimientoupdt = "Cumplido";
      }else{
        //no cumplido
        this.cumplimientoupdt = "No cumplido";
      }
      let params = {
        Opcion: 18,
        ID_REGISTRO:this.idregistro,
        CUMPLIMIENTO:this.cumplimientoupdt,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if(response.data.status == "OK"){
              console.log("Actualizado");
              this.dactcumplimiento = false;
              this.getReporteCumplimiento();
            }

            if(response.data.status == "ERROR"){
              console.log("error");
              this.dactcumplimiento = false;
            }
          }
        });
    }
  }
}


