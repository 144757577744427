var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogMustraCalendario),callback:function ($$v) {_vm.dialogMustraCalendario=$$v},expression:"dialogMustraCalendario"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Calendario de "+_vm._s(_vm.name)+" - "+_vm._s(_vm.emplid))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.closeCalendar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-sheet',{attrs:{"height":"600"}},[_c('v-container',[_c('v-card',{staticClass:"rounded-lg"},[_c('v-sheet',{staticClass:"d-flex rounded-lg",attrs:{"tile":"","height":"54"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticStyle:{"margin-top":"8px"}},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer')],1),_c('v-calendar',{ref:"calendar",staticClass:"rounded-lg",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30},on:{"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{class:event['event-text-color'],attrs:{"elevation":"3"}},[_c('v-icon',{attrs:{"small":"","color":event['event-text-color'].replace('--text', '')}},[_vm._v(" "+_vm._s(event.icon)+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(event.formatoHora))]),_vm._v(" "+_vm._s(event.name)+" ")],1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":"","max-width":"100%","min-height":"150px","transition":"scale-transition","rounded":"lg"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"elevation":"24","color":"grey lighten-4","max-width":"100%","min-height":"150px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dense":""}},[_c('v-toolbar-title',{class:_vm.selectedEvent['event-text-color'],domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer')],1),_c('v-card-text',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.respuestaDetalles.length == 0 && !_vm.loadingButtonDetails
                ),expression:"\n                  respuestaDetalles.length == 0 && !loadingButtonDetails\n                "}],domProps:{"innerHTML":_vm._s(_vm.selectedEvent.details)}}),(_vm.loadingButtonDetails && _vm.respuestaDetalles.length == 0)?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"loader"})]):(
                  !_vm.loadingButtonDetails && _vm.respuestaDetalles.length > 0
                )?_c('div',_vm._l((_vm.respuestaDetalles),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Solicitada el día: ")]),_vm._v(_vm._s(item.FLARGA)+" ")]),(item.descIncidencia != 'Tiempo por tiempo')?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Del día: ")]),_vm._v(_vm._s(item.fechaInIncidencia)+" "),_c('strong',[_vm._v(" al ")]),_vm._v(_vm._s(item.fechaFiIncidencia)+". ")]):_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Descansa el día: ")]),_vm._v(_vm._s(item.fechaInIncidencia)+". "),_c('strong',[_vm._v(" Por el dia trabajado: ")]),_vm._v(_vm._s(item.fechaFiIncidencia)+". ")])])}),0):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.respuestaDetalles.length == 0),expression:"respuestaDetalles.length == 0"}],staticClass:"rounded-lg",attrs:{"color":"teal darken-2","small":"","dark":"","loading":_vm.loadingButtonDetails},on:{"click":function($event){_vm.muestraDetalles(_vm.selectedEvent.idIncidencia);
                  _vm.loadingButtonDetails = true;}}},[_vm._v(" Detalles ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedEvent.estatus == 1),expression:"selectedEvent.estatus == 1"}],staticClass:"rounded-lg",attrs:{"small":"","dark":"","color":"red darken-2","loading":_vm.loadingBotonCancelar},on:{"click":function($event){return _vm.cancelaIncidencia(_vm.selectedEvent.idIncidencia)}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialogCargandoInf),callback:function ($$v) {_vm.dialogCargandoInf=$$v},expression:"dialogCargandoInf"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.textoCargando)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }