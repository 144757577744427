<template>
  <v-card class="v-lazy pa-4" style="min-height: 52px">
    <template v-if="!mostrarFormulario">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="agregarColaborador">Agregar</v-btn>
      </v-card-title>
      <v-data-table :no-results-text="'No se encontraron registros coincidentes'" :no-data-text="'No hay registros'"
        :headers="headers" :items="colaboradores" :search="search" :footer-props="{
      'items-per-page-text': 'Elementos por página',
      'page-text': '{0}-{1} de {2}'
    }">

        <template v-slot:[`item.actions`]="{ item }">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab x-small dark outlined class="mr-1" @click="editColaborador(item)">
            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="cyan" fab x-small dark outlined class="mr-1" @click="viewColaborador(item)">
            <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ver Información</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red" fab x-small dark outlined class="mr-1" @click="confirmDeleteColaborador(item)">
            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Eliminar</span>
      </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <FormularioColaboradores :colaborador="colaboradorEdit" :colaboradorId="colaboradorEdit.id" :isEdit.sync="isEdit"
        :isView.sync="isView" @volver="mostrarFormulario = false" />
    </template>
    <ConfirmDialogReclutamiento :showConfirm="showConfirm" :question="deleteQuestion" @cierraConfirm="dialogConfirm" />
  </v-card>
</template>

<script>
import FormularioColaboradores from './FormularioColaboradores.vue';
import ConfirmDialogReclutamiento from '../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue';
import axios from 'axios';

export default {
  components: {
    FormularioColaboradores,
    ConfirmDialogReclutamiento
  },
  mounted() {
    this.getColaboradores();
  },
  data() {
    return {
      search: '',
      mostrarFormulario: false,
      isEdit: false,
      isView: false,
      colaboradorEdit: {},
      headers: [
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: 'Nombre', value: 'Nombre' },
        { text: 'Teléfono', value: 'Telefono' },
        { text: 'Función', value: 'Funcion' },
      ],
      colaboradores: [],
      showConfirm: false,
      deleteQuestion: '¿Estás seguro de que deseas eliminar el siguiente colaborador?',
      colaboradorToDelete: null,
    }
  },
  methods: {
    agregarColaborador() {
      this.resetForm();
      this.colaboradorEdit = {};
      this.isEdit = false;
      this.isView = false;
      this.mostrarFormulario = true;
    },
    cerrarForm() {
      this.resetForm();
      this.colaboradorId = null;
      this.isEdit = false;
      this.isView = false;
      this.$emit('volver');
    },
    async getColaboradores() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getColaboradores`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.colaboradores = response.data.data.map(colaborador => ({
          id: colaborador.id,
          Nombre: colaborador.Nombre,
          Telefono: colaborador.Telefono,
          Funcion: colaborador.Funcion,
        }));

        
      } catch (error) {
        console.error('Error al cargar colaboradores:', error);
      }
    },
    async editColaborador(colaborador) {
      try {
        const numProv = 111111;
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getColaborador/${numProv}/${colaborador.id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.colaboradorEdit = response.data.data[0];
        this.isEdit = true;
        this.isView = false;
        this.mostrarFormulario = true;
      } catch (error) {
        console.error('Error al cargar colaborador:', error);
      }
    },
    confirmDeleteColaborador(colaborador) {
      this.colaboradorToDelete = colaborador.id;
      this.deleteQuestion = `¿Estás seguro de que deseas eliminar al colaborador ${colaborador.Nombre}?`;
      this.showConfirm = true;
    },
    async deleteColaborador(id) {
      try {
        const response = await axios.put(`${this.$store.getters.getHostNode}/api/eliminarColaborador/${id}` , {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.status) {
          this.getColaboradores();
        }
      } catch (error) {
        console.error('Error al eliminar colaborador:', error);
      }
    },
    dialogConfirm(confirm) {
      this.showConfirm = false;
      if (confirm && this.colaboradorToDelete) {
        this.deleteColaborador(this.colaboradorToDelete);
      }
      this.colaboradorToDelete = null;
    },
    async viewColaborador(colaborador) {
      try {
        const numProv = 866117;
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getColaborador/${numProv}/${colaborador.id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.colaboradorEdit = response.data.data[0];
        this.isEdit = false;
        this.isView = true;
        this.mostrarFormulario = true;
      } catch (error) {
        console.error('Error al cargar colaborador:', error);
      }
    },
    resetForm() {
      this.colaboradorEdit = {};
    }
  }
}
</script>