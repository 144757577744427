<template>
  <v-dialog
    v-model="muestraBuscaPuestos"
    class="rounded-lg"
    persistent
    max-width="750"
  >
    <v-card class="rounded-lg fondo-ventana">
      <v-card-title
        class="py-2 pl-5 primary white--text subtitle-1 font-weight-bold"
      >
        Selecciona el puesto
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraBuscaPuesto', 0)"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="puestoSeleccionado"
          placeholder="Busca por nombre"
          label="Puesto Urrea"
          dense
          outlined
          flat
          eager
          :items="puestos"
          item-text="DESCR"
          :loading="loadContent"
          class="pa-0 mx-3 mt-5 rounded-lg"
          v-on:change="buscaSeleccionado"
        >
        </v-autocomplete>
        <div v-if="descPuestoSel.DESCR != ''">
          <v-simple-table dense>
            <tbody>
              <tr>
                <td class="text--disabled">Puesto</td>
                <td class="text--disabled">JOBCODE</td>
                <td class="text--disabled">Puestos existentes</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ descPuestoSel[0].DESCR }}</td>
                <td>{{ descPuestoSel[0].JOBCODE }}</td>
                <td>{{ descPuestoSel[0].colaboradoresAct }} colaborador(es)</td>
                <td class="text-right">
                  <v-btn
                    color="#f2f2f2"
                    depressed
                    small
                    rounded
                    v-if="descPuestoSel[0].id != null"
                    @click="solicitarPuestoExistente(descPuestoSel[0].JOBCODE)"
                  >
                    <v-icon left color="primary"> mdi-account-plus </v-icon>
                    <p class="my-2 primary--text font-weight-bold">
                      solicitar puesto
                    </p>
                  </v-btn>
                  <v-btn color="#f2f2f2" depressed small rounded dark v-else>
                    <v-icon left color="pink darken-4">
                      mdi-file-document-alert
                    </v-icon>
                    <p class="my-2 pink--text text--darken-4 font-weight-bold">
                      solicitar políticas
                    </p>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <SolicitaExistente
      v-if="solicitaExistente"
      :muestraSolicitaPuestoExistente="solicitaExistente"
      :idPuesto="idPuesto"
      @cierraSolicitud="cierraSolicitud"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
// import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SolicitaExistente from "./SolicitaPuestoExistente.vue";

export default {
  name: "busca-puestos",
  props: ["muestraBuscaPuestos"],
  components: { SolicitaExistente },
  data() {
    return {
      loadContent: true,
      puestoSeleccionado: "",
      puestos: [],
      idPuesto: "",
      solicitaExistente: false,
      descPuestoSel: {
        DESCR: "",
        JOBCODE: "",
        colabolaroresAct: 0,
        id: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.getPuestosPS();
  },
  methods: {
    getPuestosPS() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.puestos = response.data.puestos;
            this.loadContent = false;
          }
        });
    },
    // getPuestosZN() {
    //   const headers = { Authorization: localStorage.getItem("token") };
    //   return new Promise((res) => {
    //     axios
    //       .get(`${this.$store.getters.getHostNode}/api/get-puestos-zn`, {
    //         headers,
    //       })
    //       .then((response) => {
    //         if (response.data.status == "OK") {
    //           res(response.data.data);
    //           this.puestos = response.data.data;
    //         } else if (response.data.status == "EXPRESSION") {
    //           deleteToken();
    //         } else {
    //           res([]);
    //         }
    //       })
    //       .finally(() => {
    //         this.loadContent = false;
    //       });
    //   });
    // },
    buscaSeleccionado() {
      this.descPuestoSel = this.puestos.filter(this.buscaSelected);
      console.log(this.descPuestoSel);
    },
    buscaSelected(val) {
      return val.DESCR.trim() == this.puestoSeleccionado.trim();
    },
    solicitarPuestoExistente(jobcode) {
      this.idPuesto = jobcode;
      // console.log("JOBCODE: " + this.idPuesto)
      this.solicitaExistente = true;
    },
    cierraSolicitud(modoCierre) {
      this.$emit("cierraBuscaPuesto", modoCierre);
    },
  },
};
</script>

<style>
.fondo-ventana {
  background: rgb(227, 227, 227);
  background: radial-gradient(
    circle,
    rgba(227, 227, 227, 1) 0%,
    rgba(234, 242, 245, 0.5) 100%
  );
}
</style>
