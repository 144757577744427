<template>
    <v-dialog v-model="updateUsers" persistent max-width="800px">
        <v-card class="rounded-lg">
            <v-card-title class="primary white--text subtitle-1">
                <v-spacer></v-spacer>
                <v-icon dark @click="closeDialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-4">
                <v-form ref="form"  lazy-validation>
                    <v-row dense>
                        <v-col cols="6">
                            <v-text-field v-model="nombre" prepend-inner-icon="mdi-account" dense outlined class="rounded-lg py-0 my-0" label="Nombre" counter="60">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="correo" prepend-inner-icon="mdi-at" dense outlined class="rounded-lg py-0 my-0" label="Correo" counter="50">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="5">
                            <v-text-field v-model="nickname" prepend-inner-icon="mdi-account" dense outlined class="rounded-lg py-0 my-0" label="NickName" counter="20">
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field v-model="password" prepend-inner-icon="mdi-account-key" dense outlined class="rounded-lg py-0 my-0" label="Contraseña" counter="50">
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="rounded-lg" dark block color="primary" @click="generateText">Generar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="rounded-lg" dark color="teal darken-1" depressed @click="saveData">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        showDialog: {
            type: Boolean,
            required: true
        },
        providerData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            updateUsers: this.showDialog,
            id: this.providerData.id || '', 
            nombre: this.providerData.Nombre || '',
            correo: this.providerData.Correo || '',
            nickname: this.providerData.Nickname || '',
            password: this.providerData.Password || '',
        };
    },
    watch: {
        showDialog(val) {
            this.updateUsers = val;
        },
        updateUsers(val) {
            this.$emit('update:showDialog', val);
        },
        providerData: {
            deep: true,
            handler(newVal) {
                this.id = newVal.id || '';
                this.nombre = newVal.Nombre || '';
                this.correo = newVal.Correo || '';
                this.nickname = newVal.Nickname || '';
                this.password = newVal.Password || '';
            }
        }
    },
    methods: {
        generateText() {
            let text = '';
            let hasSpecialChar = false;
            let hasNumber = false;
            const minLength = 8;
            const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$*%#';

            while (text.length < minLength || !hasSpecialChar || !hasNumber || !/^[a-zA-Z]/.test(text)) {
                text = '';
                hasSpecialChar = false;
                hasNumber = false;
                for (let i = 0; i < minLength; i++) {
                    const char = validChars.charAt(Math.floor(Math.random() * validChars.length));
                    text += char;
                    if (!hasSpecialChar && '@$*%#'.includes(char)) {
                        hasSpecialChar = true;
                    }
                    if (!hasNumber && '0123456789'.includes(char)) {
                        hasNumber = true;
                    }
                }
            }

            this.password = text;
        },
        closeDialog() {
            this.updateUsers = false;
        },
        async saveData() {
            try {
               
                const response = await axios.put(`${this.$store.getters.getHostNode}/api/editarPass/${this.id}`, {
                    password: this.password
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                console.log(response)
            } catch (error) {
                console.error('Error al actualizar el proveedor:', error);
            }
            this.closeDialog();
        }
    }
};
</script>
