<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <v-text-field
        v-model="search"
        class="ml-2"
        style="max-width: 450px;"
        dense
        prepend-inner-icon="mdi-clipboard-text-search-outline"
        placeholder="buscar..."
      ></v-text-field>
      <v-btn
        class="back-btn-update font-weight-bold mb-2 rounded-pill"
        small
        depressed
        @click="
          loadingContentSolicitudes = true;
          getSolicitudesPendientes();
        "
      >
        <v-icon left> mdi-update </v-icon>
        actualizar
      </v-btn>
    </div>
    <div class="py-2" style="display: flex; justify-content: space-between;">
      <div>
        <v-btn
          :color="estatusSolicitudes === 0 ? 'primary' : '#A8A8A7'"
          depressed
          class="rounded-pill mx-2"
          dark
          @click="
            estatusSolicitudes = 0;
            loadingContentSolicitudes = true;
            getSolicitudesPendientes();
          "
        >
          Pendientes
        </v-btn>
        <v-btn
          :color="estatusSolicitudes === 1 ? 'primary' : '#A8A8A7'"
          depressed
          class="rounded-pill mx-2"
          dark
          @click="
            estatusSolicitudes = 1;
            loadingContentSolicitudes = true;
            getSolicitudesPendientes();
          "
        >
          Autorizadas
        </v-btn>
        <v-btn
          :color="estatusSolicitudes === 2 ? 'primary' : '#A8A8A7'"
          depressed
          class="rounded-pill mx-2"
          dark
          @click="
            estatusSolicitudes = 2;
            loadingContentSolicitudes = true;
            getSolicitudesPendientes();
          "
        >
          Rechazadas
        </v-btn>
      </div>
      <label class="overline font-weight-bold">
        Solicitudes: {{ solicitudesPendientes === undefined ? 0 : solicitudesPendientes.length }}
      </label>
    </div>
    <v-data-table
      :loading="loadingContentSolicitudes"
      :headers="headers_solicitudes"
      :items="solicitudesPendientes"
      :expanded.sync="expanded"
      :search="search"
      height="100%"
      loading-text="Cargando contentenido..."
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      no-data-text="No hay registros"
      :header-props="{
        'short-by-text': 'Ordenar por',
      }"
      class="neumorphism-card-reclutamiento rounded-lg"
      style="border: 0.8px solid #CFD8DC !important;"
      
      show-expand
      dense
    >
      <template v-slot:data-table-expand class="px-0"> </template>
      <template v-slot:expanded-item="{ headers, item }" class="px-1">
        <td :colspan="headers.length" class="px-0 mx-0">
          <v-row no-gutters>
            <v-col cols="12" style="display:flex; justify-content:center;">
              <div class="rounded-lg rounded-t-0 back-titulo-detalles sombra">
                <p style="color:white; font-weight:bold; font-size: 1.1rem;">
                  Detalle de solicitud
                </p>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Puesto URREA
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                <strong>{{ item.idPuesto_asociado }}</strong> -
                {{ item.puestoUrrea }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Solicitante
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                <strong>{{ item.solicitante }} </strong>-
                {{ item.nomSolicitante }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Área - Departamento
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                <strong>{{ item.area }} </strong>- {{ item.departamento }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Tipo de puesto
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.tipoDesc }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Razón de solicitud ({{ item.razonDesc }})
              </v-subheader>
              <v-subheader
                class="py-0 mt-0"
                v-if="
                  item.razon_solicitud != '' && item.razon_solicitud != null
                "
              >
                {{ item.razon_solicitud }}
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0" v-else>
                <strong>{{ item.colaborador_involucrado }}</strong> -
                {{ item.EMP_NOMEMP }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Lugar de trabajo
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.lugar }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Equipo requerido:
              </v-subheader>
              <ul style="margin-left: 4px;">
                <li v-if="item.req_laptop == 1">Laptop</li>
                <li v-if="item.req_celular == 1">Celular</li>
                <li v-if="item.req_epp == 1">EPP</li>
              </ul>
            </v-col>
            <v-col
              v-if="item.comision == 1"
              cols="12"
              lg="3"
              md="3"
              sm="6"
              xs="12"
            >
              <v-subheader
                class="py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
                style="height: 25px"
              >
                Comisión:
              </v-subheader>
              <v-subheader class="text-left" style="height: 25px">
                Valor: {{ item.comisionval }}. Garantía: {{ item.comisiongar }}.
                Meses: {{ item.comisionmes }}.
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Fecha de solicitud
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.fecha_solicitud }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Sueldo de contratación
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ formatMXN(parseFloat(item.Contratacion)) }}
              </v-subheader>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="3"
              lg="3"
              v-if="item.nombre_curso != null"
            >
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Curso seleccionado
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.nombre_curso }}
              </v-subheader>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="3"
              lg="2"
              v-if="item.candidato_recomendado_nombre != null"
            >
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Candidato recomendado
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.candidato_recomendado_nombre }}
              </v-subheader>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="3"
              lg="2"
              v-if="item.candidato_recomendado_correo != null"
            >
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Correo de candidato
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.candidato_recomendado_correo }}
              </v-subheader>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mx-4 mb-4"></v-divider>
          <v-row no-gutters class="mb-4">
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center">
              <v-btn
                class="rounded-pill elevation-1 font-weight-bold"
                color="teal lighten-2"
                depressed
                dark
                :loading="loadEntrevistadores"
                @click="
                  loadEntrevistadores = true;
                  getEntrevistadores(item);
                "
              >
                Ver entrevistadores
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center">
              <v-btn
                class="rounded-pill elevation-1 font-weight-bold"
                color="teal lighten-2"
                depressed
                dark
                :loading="loadingApp"
                @click="
                  loadingApp = true;
                  getApegoPerfil(item);
                "
              >
                Ver apego a perfil
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center">
              <v-btn
                class="rounded-pill elevation-1 font-weight-bold"
                color="teal lighten-2"
                depressed
                dark
                :loading="loadPreguntasClave"
                @click="
                  loadPreguntasClave = true;
                  getPreguntasClave(item);
                "
              >
                Ver preguntas clave
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.estatus_desc == 'Pendiente'">
          <div
            v-if="
              item.conocimientos_sin_autorizar == 0 ||
                item.conocimientos_sin_autorizar == null
            "
          >
            <v-tooltip top small color="green darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-1"
                  icon
                  depressed
                  color="green darken-2"
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="openConfirmDialog(item)"
                >
                  <v-icon> mdi-check-bold </v-icon>
                </v-btn>
              </template>
              <p class="text-uppercase mb-0 font-weight-bold body-2">
                Autorizar
              </p>
            </v-tooltip>
            <v-tooltip top small color="red darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  color="red darken-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="openAddComments(item)"
                >
                  <v-icon> mdi-close-thick </v-icon>
                </v-btn>
              </template>
              <p
                class="text-uppercase mb-0 font-weight-bold body-2 pink--text text--lighten-5"
              >
                Rechazar
              </p>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip top small color="blue-grey lighten-5">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  class="elevation-0 rounded-pill font-weight-bold blue-grey--text text--darken-3"
                  color="blue-grey lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: pointer;"
                >
                  <v-icon left small color="pink darken-3"
                    >mdi-timer-sand</v-icon
                  >
                  Aut. Compesaciones
                </v-chip>
              </template>
              <p
                class="text-uppercase mb-0 text-center font-weight-bold body-2 blue-grey--text text--darken-3"
              >
                En espera de la autorización <br />
                de compensaciones
              </p>
            </v-tooltip>
          </div>
        </div>
        <v-chip
          v-else-if="item.estatus_desc == 'Aceptada'"
          small
          class="elevation-0 rounded-pill font-weight-bold teal--text text--darken-1"
          color="teal lighten-5"
        >
          <v-icon left small color="teal darken-1">mdi-check-bold</v-icon>
          {{ item.estatus_desc }}
        </v-chip>
        <v-chip
          v-else
          small
          class="elevation-0 rounded-pill font-weight-bold pink--text text--darken-3"
          color="pink lighten-5"
        >
          <v-icon left small color="pink darken-3">mdi-close-thick</v-icon>
          {{ item.estatus_desc }}
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog v-model="commentReject" persistent max-width="600">
      <v-card class="rounded-lg">
        <v-card-title class="pt-2 primary white--text">
          Justifica la acción.
          <v-spacer></v-spacer>
          <v-icon dark @click="commentReject = false"> mdi-close-thick </v-icon>
        </v-card-title>

        <v-card-subtitle
          class="primary pb-2 blue-grey--text text--lighten-3 text-uppercase"
        >
          {{ requestName }} ({{ requestId }})
        </v-card-subtitle>

        <v-card-text class="pt-2 pb-0">
          <v-form ref="formComments" v-model="valid" lazy-validation>
            <v-textarea
              v-model="commentRejectText"
              class="rounded-lg"
              outlined
              :rows="1"
              :rules="commentRejectRules"
              :disabled="loadingBtnRejectRequest"
              required
              placeholder="Escribe el comentario de rechazo."
              prepend-inner-icon="mdi-comment"
              counter="250"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="rounded-lg elevation-0"
            block
            color="primary"
            :loading="loadingBtnRejectRequest"
            @click="
              loadingBtnRejectRequest = true;
              updateEstatus(2);
            "
          >
            Rechazar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TablaDetalles
      v-if="muestraTablaGenerica"
      :verDataTable="muestraTablaGenerica"
      :esApegoPerfil="esApegoPerfil"
      :items="itemsDataRequest"
      :headers="headersTable"
      :titulo="tituloTabla"
      @cierraTabla="muestraTablaGenerica = false"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="openConfirm"
      :question="question"
      @cierraConfirm="verifyAnswer"
    />

    <LoadingBar text="Generando vacante..." :dialog="muestraLoading" />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../store/Funciones/funciones";
import TablaDetalles from "./TablaDetalles.vue";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import ConfirmDialogReclutamiento from "./ConfirmDialogReclutamiento.vue";
import LoadingBar from "../../DesarrolloOrganizacional/components/loadingBar.vue";

export default {
  name: "solicitudes-pendientes",
  components: {
    TablaDetalles,
    SnackBar,
    ConfirmDialogReclutamiento,
    LoadingBar,
  },
  props: [],
  data() {
    return {
      loadingContentSolicitudes: true,
      valid: true,
      loadEntrevistadores: false,
      loadPreguntasClave: false,
      muestraTablaGenerica: false,
      loadingBtnRejectRequest: false,
      commentReject: false,
      muestraBarra: false,
      esApegoPerfil: false,
      loadingApp: false,
      openConfirm: false,
      muestraLoading: false,
      solicitudesPendientes: [],
      expanded: [],
      entrevistadores: [],
      headersTable: [],
      itemsDataRequest: [],
      commentRejectRules: [
        (v) => !!v || "Campo requiredo.",
        (v) =>
          (v && v.length <= 250) ||
          "el campo debe tener menos de 250 caracteres.",
      ],
      tituloTabla: "",
      requestName: "",
      commentRejectText: "",
      textoBarra: "",
      colorBarra: "",
      question: "",
      search: "",
      estatusSolicitudes: 0,
      requestId: null,
    };
  },
  computed: {
    headers_solicitudes() {
      return [
        {
          text: "",
          value: "data-table-expand",
          class: "heder-requests-custom rounded-tl-lg px-1",
        },
        {
          text: "ID",
          align: "start",
          value: "id",
          class: "heder-requests-custom",
        },
        {
          text: "# de posiciones",
          align: "center",
          value: "num_vacantes",
          class: "heder-requests-custom",
        },
        {
          text: "Puesto",
          align: "start",
          value: "nombre",
          class: "heder-requests-custom",
        },
        {
          text: "Razón de solicitud",
          align: "start",
          value: "razonDesc",
          class: "heder-requests-custom",
        },
        {
          text: "Tipo de puesto",
          align: "start",
          value: "tipoDesc",
          class: "heder-requests-custom",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          class: "heder-requests-custom rounded-tr-lg",
        },
      ];
    },
  },
  mounted() {
    this.getSolicitudesPendientes();
  },
  methods: {
    getSolicitudesPendientes() {
      if (this.solicitudesPendientes != undefined) {
        if (this.solicitudesPendientes.length > 0) {
          this.solicitudesPendientes.length = 0;
        }
      }
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        estatus: this.estatusSolicitudes,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.solicitudesPendientes = response.data.solicitudes;
            this.loadingContentSolicitudes = false;
          }
        });
    },
    autorizar() {
      // console.log(this.requestSelected);
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        idSolicitud: this.requestSelected.id,
        nombre: this.requestSelected.nombre,
        solicitante: this.requestSelected.solicitante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.muestraLoading = false;
          if (response.data.status == "EXPSESSION") {
            // deleteToken();
            console.log("deleteToken");
          } else if (response.data.status == "OK") {
            if (response.data.solicitud.statusTransaction == "CREATED") {
              let indice = this.solicitudesPendientes.findIndex(
                (solicitud) => solicitud === this.requestSelected
              );
              this.solicitudesPendientes[indice].estatus_desc = "Aceptada";
              this.muestraSnackBar(response.data.solicitud.message, "primary");
            } else {
              this.muestraSnackBar(
                response.data.solicitud.message,
                "pink darken-3"
              );
            }
          } else {
            this.muestraSnackBar(
              "Ocurrió un error. No se pudo autorizar. BE",
              "pink darken-3"
            );
          }
        })
        .catch((error) => {
          this.muestraSnackBar(error, "pink darken-3");
        });
    },
    getEntrevistadores(item) {
      this.entrevistadores.length = 0;
      this.headersTable.length = 0;
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        idSolicitud: item.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.loadEntrevistadores = false;
            response.data.entrevistadores.map((entrevistador) => {
              this.entrevistadores.push({
                id: entrevistador.id,
                idSolicitud: entrevistador.id_solicitud,
                entrevistador:
                  entrevistador.EMP_NOMEMP +
                  " (" +
                  entrevistador.entrevistador +
                  "). " +
                  entrevistador.EMP_STATUS +
                  ".",
                tipo: entrevistador.es_comite,
              });
            });
            this.itemsDataRequest = this.entrevistadores;
            this.headersTable.push({
              text: "ID",
              align: "center",
              value: "id",
              class:
                "primary white--text text-uppercase font-weight-bold rounded-tl-lg dark",
            });
            this.headersTable.push({
              text: "ID Solicitud",
              align: "center",
              value: "idSolicitud",
              class: "primary white--text text-uppercase font-weight-bold dark",
            });
            this.headersTable.push({
              text: "Entrevistador",
              align: "center",
              value: "entrevistador",
              class: "primary white--text text-uppercase font-weight-bold dark",
            });
            this.headersTable.push({
              text: "Tipo",
              align: "center",
              value: "tipo",
              class:
                "primary white--text text-uppercase font-weight-bold dark rounded-tr-lg",
            });
            this.esApegoPerfil = false;
            this.tituloTabla = "Entrevistadores de " + item.nombre;
            this.muestraTablaGenerica = true;
          }
        });
    },
    getPreguntasClave(item) {
      this.entrevistadores.length = 0;
      this.headersTable.length = 0;
      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
        idSolicitud: item.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.loadPreguntasClave = false;
            this.itemsDataRequest = response.data.preguntasClave;
            this.headersTable.push({
              text: "ID",
              align: "center",
              sortable: false,
              value: "id",
              class:
                "primary white--text text-uppercase font-weight-bold rounded-tl-lg",
            });
            this.headersTable.push({
              text: "ID Solicitud",
              align: "center",
              sortable: false,
              value: "id_solicitud",
              class: "primary white--text text-uppercase font-weight-bold",
            });
            this.headersTable.push({
              text: "Pregunta",
              align: "justify",
              value: "pregunta",
              class: "primary white--text text-uppercase font-weight-bold",
            });
            this.headersTable.push({
              text: "Respuesta",
              align: "justify",
              value: "respuesta",
              class:
                "primary white--text text-uppercase font-weight-bold rounded-tr-lg",
            });
            this.esApegoPerfil = false;
            this.tituloTabla = "Preguntas clave " + item.nombre;
            this.muestraTablaGenerica = true;
          }
        });
    },
    getApegoPerfil(item) {
      this.entrevistadores.length = 0;
      this.headersTable.length = 0;
      let params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        solicitud: item.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.loadingApp = false;
            this.esApegoPerfil = true;
            this.itemsDataRequest = response.data.solicitud;
            this.tituloTabla = "Apego a perfil solicitado.";
            this.muestraTablaGenerica = true;
          }
        })
        .catch((e) => {
          alert(e);
          deleteToken();
        });
    },
    updateEstatus(estatus) {
      if (this.$refs.formComments.validate()) {
        let params = {
          Opcion: 15,
          token: localStorage.getItem("token"),
          solicitud: this.requestId,
          comentario: this.commentRejectText,
          estatus: estatus,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingBtnRejectRequest = false;
            this.commentReject = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              if (response.data.solicitud.modified) {
                this.colorBarra = "teal darken-1";
                this.textoBarra = "Modificado con éxito. c:";
                this.muestraBarra = true;
                this.loadingContentSolicitudes = true;
                this.getSolicitudesPendientes();
              } else {
                this.colorBarra = "pink darken-3";
                this.textoBarra = "Error. No se pudo modificar. :c";
                this.muestraBarra = true;
              }
            } else {
              this.colorBarra = "pink darken-3";
              this.textoBarra = "Error. No se pudo modificar. :c";
              this.muestraBarra = true;
            }
          });
      }
    },
    openAddComments(request) {
      this.requestName = request.nombre;
      this.requestId = request.id;
      this.commentReject = true;
    },
    openConfirmDialog(item) {
      this.requestSelected = item;
      this.question = "¿Realmente deseas autorizar esta solicitud?";
      this.openConfirm = true;
    },
    verifyAnswer(answer) {
      if (!answer) {
        this.openConfirm = false;
      } else {
        this.openConfirm = false;
        this.muestraLoading = true;
        this.autorizar();
      }
    },
    muestraSnackBar(text, color) {
      this.muestraBarra = true;
      this.colorBarra = color;
      this.textoBarra = text;
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}

.sombra {
  box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
}

.back-titulo-detalles {
  width: 98%;
  height: 30px;
  margin-bottom: 4px;
  background: rgb(64, 157, 215);
  background: linear-gradient(
    230deg,
    rgba(64, 157, 215, 1) 0%,
    rgba(150, 71, 251, 1) 47%,
    rgba(200, 107, 184, 1) 99%
  );
  display: flex;
  justify-content: center;
}

.background-glass-effect {
  background: rgba(100, 163, 217, 0.4) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.3px);
  -webkit-backdrop-filter: blur(9.3px);
  border: 1px solid rgba(255, 255, 255, 1);
}

.custom-width-expand-colum {
  max-width: 5px !important;
}

.background-glass-effect-possitions-request {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(
    60deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(228, 228, 228, 1) 45%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 3px solid rgba(255, 255, 255, 1) !important;
}

.heder-requests-custom {
  background-color: rgb(0, 120, 195);
  color: white !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.back-btn-update {
  background: rgb(159, 208, 240) !important;
  background: linear-gradient(
    94deg,
    rgba(214, 239, 255, 1) 4%,
    rgba(215, 215, 215, 1) 43%,
    rgba(188, 213, 255, 1) 100%
  ) !important;
}

::v-deep .v-data-table__expand-icon {
  padding-right: 3px !important;
  padding-left: 3px !important;
}
</style>
